import { CORPORATES_BASE_URL } from "../../utils/utils";

export const getCorporateWiseFilter = (params) => {
    const { pageNo, fType } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const fUrl = fType == 'industry' ? `${CORPORATES_BASE_URL}corporates/top-10-industries-wise-companies?page=${pageNo}`
        : fType == 'state' ? `${CORPORATES_BASE_URL}corporates/top-10-state-wise-companies?page=${pageNo}`
        : fType == 'city' ? `${CORPORATES_BASE_URL}corporates/top-10-city-wise-companies?page=${pageNo}`
        : fType == 'locality' ? `${CORPORATES_BASE_URL}corporates/top-10-locality-wise-companies?page=${pageNo}`
        : fType == 'type' ? `${CORPORATES_BASE_URL}corporates/top-10-different-companies-type?page=${pageNo}`:null;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getCompaniesByTypeWise = (params) => {
    const { pageNo, fType, typeWiseSlug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders
    };
    const fUrl = fType == 'industry' ? `${CORPORATES_BASE_URL}corporates/industry/${typeWiseSlug}?page=${pageNo}`
        : fType == 'state' ? `${CORPORATES_BASE_URL}corporates/state/${typeWiseSlug}?page=${pageNo}`
        : fType == 'city' ? `${CORPORATES_BASE_URL}corporates/city/${typeWiseSlug}?page=${pageNo}`
        : fType == 'locality' ? `${CORPORATES_BASE_URL}corporates/locality/${typeWiseSlug}?page=${pageNo}`
        : fType == 'type' ? `${CORPORATES_BASE_URL}corporates/type/${typeWiseSlug}?page=${pageNo}`:null;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const getCorporateCompanyDetail = (params) => {
    const { companySlug, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    let requestOptions = {
        method: "GET",
        headers: myHeaders
    };
    const fUrl =  `${CORPORATES_BASE_URL}corporates/company/${companySlug}`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const writeReviewCompany = (params) => {
    const { corporate_id, reviews, rating, token } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("corporate_id", corporate_id);
    formData.append("reviews", reviews);
    formData.append("rating", rating);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${CORPORATES_BASE_URL}corporates/add-review`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const reportAgainstCompany = (params) => {
    const { corporate_id, name, email, report, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("corporate_id", corporate_id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("report", report);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${CORPORATES_BASE_URL}corporates/report`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const followCompany = (params) => {
    const { corporate_id, status, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("corporate_id", corporate_id);
    formData.append("status", status);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${CORPORATES_BASE_URL}corporates/follow`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const claimAccountCompany = (params) => {
    const { corporate_id, name, email, phone , token, description } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("corporate_id", corporate_id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("description", description);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${CORPORATES_BASE_URL}corporates/claims`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const corporateCommentAndReply = (params) => { 
    const { corporate_id, comment, commentId, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('id', corporate_id);
    formData.append('comment', comment);
    formData.append('comment_id', commentId || 0);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${CORPORATES_BASE_URL}corporates/comment`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };