import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import StarRatings from 'react-star-ratings';
import { useAuth } from '../../../../Context/AuthContext';
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL
} from '../../../../utils/utils';
import {
    blogsCommentAndReply,
    likeUnlikeDislike
} from '../../../../api/blog';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import ViewAllButton from '../../../common/viewallbutton';
import CommonComments from '../../../common/comments/commonComments';
import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import LyricsComments from '../../../common/comments/lyricsComments';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import { getBlogDetails } from '../../../../api/blog';
import BlogsComments from '../../../common/comments/blogsComments';

function BlogDetail() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [blogDetailData, setBlogDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [blogId, setBlogId] = useState();
    const [blogSlug, setBlogSlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);

    // Fetches lyrics details when component mounts or params change
    useEffect(() => {
        if (params?.blogSlug) {
            const bId = (params?.blogSlug).split('-').pop();
            setBlogSlug(params?.blogSlug);
            setBlogId(bId)
            handleFetchBlogDetail(bId);
        }
    }, []);

    // Handles fetching lyrics details by lyrics ID
    const handleFetchBlogDetail = async (bId) => {
        const resBlogsDetail = await getBlogDetails({ token: globalToken, blogId:bId });
        // console.log('blog detail', resBlogsDetail);
        setIsLiked(resBlogsDetail?.data?.is_like);
        setIsDisliked(resBlogsDetail?.data?.is_dislike);

        setLoading(false);
        setLoadingLoadMore(false);
        if (resBlogsDetail?.success) {
            setIsNotFound(false);
            if (resBlogsDetail?.data?.details) {
                setBlogDetailData(resBlogsDetail?.data?.details);
            } else {
                setBlogDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false);
    }

    // Handles adding/removing lyrics from favorites
    // const FavouriteButton = ({ is_fav_camp }) => {
    //     const [favBtnLoading, setFavBtnLoading] = useState(false);

    //     const handleStartTask = async () => {
    //         setFavBtnLoading(true);
    //         const token = globalToken;
    //         const res = await bookMarkLyrics({ token: token, lyricId: blogDetailData.id, favourite: is_fav_camp ? 0 : 1 });
    //         if (res?.success) {
    //             handleFetchBlogDetail(blogDetailData.id);
    //         }
    //         setFavBtnLoading(false);
    //     }

    //     return (
    //         <button
    //             type="button"
    //             disabled={favBtnLoading}
    //             onClick={handleStartTask}
    //             className="btn bg-white p-0 d-flex btn-like-circular"
    //             style={{ border: 'none', boxShadow: 'none' }}
    //         >
    //             {favBtnLoading ? (
    //                 <div className="h-5 w-5">
    //                     <Loadingicon />
    //                 </div>
    //             ) : (
    //                 <>
    //                     {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
    //                 </>
    //             )}
    //         </button>
    //     );
    // }

    // Handles sharing the lyrics
    const InlineShare = ({ blogsData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubBlogs}/detail/${blogsData?.slug}`,
                            quote: `${blogsData?.description}`,
                            hashing: `#tuteehub`,
                            title: `${blogsData?.title}`,
                        }}
                        isCustomPos={true}
                    />
                ) : null}
                <span
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, articleId: blogDetailData.id, voteType });
        if (resLikeUnlike?.success) {
            handleFetchBlogDetail(blogDetailData.id);
            toast.success(resLikeUnlike?.data?.data?.message);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {
        commentLoader(true);
        try {
            const resCommentCommit = await blogsCommentAndReply({
                token: globalToken,
                blogId: blogDetailData?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message);
                handleFetchBlogDetail(blogDetailData?.id);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };

    const modifyAnchorTagsSelf = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_self');
        });
        return doc.documentElement.innerHTML;
    };

    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : (
                <>
               <div className='d-flex justify-content-between mb-3'>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/blogs' style={{ fontSize: 14, textDecoration: 'none' }}>Blogs</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/blogs/${blogDetailData?.cat?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{blogDetailData?.cat?.name}</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/blogs/topic/${blogDetailData?.scat?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{blogDetailData?.scat?.name}</NavLink>
                            </li>
                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.blogSlug)}
                            </li>
                        </ol>
                    </nav>
                    <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/my-blogs')}
                            >
                                My Blogs
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/post-a-blog')}
                            >
                            Post A Blog
                            </button>                    
                    </div>
                </div>
                    <div className="row">
                    <div className="col-md-12">
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                            <div className="px-0">
                                <div className="flex-column d-flex flex-md-row justify-content-between">

                                    <div className="mr-md-5 pr-md-5 flex-1">
                                        <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                            <strong>{blogDetailData?.title}</strong>
                                            <span className="d-flex gap-3 align-items-center">
                                                {/* <FavouriteButton  is_fav_camp={blogDetailData?.isuserfavourite==0?false:true}/> */}
                                                <InlineShare blogsData={blogDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive}/> 
                        </span>
                                        </h1>
                                        <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                                                
                                            <span className="text-black-50 gig-table items-center d-flex">
                                                {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/${blogDetailData?.cat?.slug}`)}>{blogDetailData?.cat?.name}</a>
                                            <span className="big-dots"></span>
                                             <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/topic/${blogDetailData?.scat?.slug}`)}>{blogDetailData?.scat?.name}</a>
                                             <span className="big-dots"></span> */}
                                             <span>{nFormatter(blogDetailData?.views)} views</span>
                                             <span className="big-dots"></span>
                                             <span>{blogDetailData?.comments_count} comments</span>
                                             <span className="big-dots"></span>
                                             <span>{blogDetailData?.alikes_count} up votes</span>
                                             {
                                                    upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-up cursor-pointer ms-2 ${isLiked && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(isLiked ? "unlike" : "like",'lke')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{blogDetailData?.adislikes_count} down votes</span>
                                             {
                                                    downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-down cursor-pointer ms-2 ${isDisliked && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{nFormatter(blogDetailData?.share_count)} shares</span>
                                             <span className='big-dots'></span>
                                             <span>{convertDateFormat(blogDetailData?.posting_date || blogDetailData?.created_at)}</span>
                                             </span>
                                            {/* <span className="text-black-50">Published on {convertDateFormat(blogDetailData?.posting_date)}</span> */}

                                        </div>
                                        <div className="thumbnail-containers rounded w-100">                                            {
                                                blogDetailData?.image?<img src={CDN_BASE_URL + blogDetailData?.image} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />:<img src={placeholderImages} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />
                                            }
                                            
                                        </div>
                                       
                                        <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: modifyAnchorTags(`${blogDetailData?.description?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                        }}></div>

                                    </div>

                                </div>


                            </div>
                        </div>
                       
                        {
                            blogDetailData?.comments_count>0? <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({blogDetailData?.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <BlogsComments cData={blogDetailData} handleSubmitComment={handleSubmitComment}/>
                        </div>:null
                        }
                       
                    </div>
            <div className='col-md-12'>
            <div className="col-md-12 my-4 w-full  border-none child-menu   card-body">
            {/* <SimilarNews newsSlug={blogDetailData?.slug} patternLinks={patternLinks} redirectionFn={()=>alert(1)} stripBottom={false}/> */}
          </div>
          </div>
                </div>
                </>
            )}
        </>
    );
}

export default BlogDetail;
