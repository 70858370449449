import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

export default function TutorialChapters({chaptersList, showArrow,tutorialId, tutorialSlug, title, handleFetchTutorialDetail, handleTutorialDetailByChapter}) {
  const navigate = useNavigate();
  const location = useLocation(); 
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="heading-main position-relative my-3 overflow-hidden">
          <h5 className="text-left bg-white pr-3" style={{width:'fit-content'}}><strong>{title}</strong></h5>
          <hr className="heading-hr mb-0" />
      </div>
      
    <div className="links-container">
        <Link onClick={()=>{navigate(`/tutorials/detail/${tutorialSlug}`);window.location.reload();}}  className={`right-side-links w-100 d-flex justify-content-between align-items-center ${isActive(`/tutorials/detail/${tutorialSlug}`) ? 'active' : ''}`}>Chapter 1: Introduction
            {
              showArrow?<i className='fa fa-angle-right ml-3' style={{opacity:.6}}></i>:null
            }
            
        </Link>
        {
            chaptersList?.map((chapter, i)=><Link onClick={()=>{navigate(`/tutorials/detail/${tutorialSlug}/${chapter?.slug}-${chapter?.id}`);window.location.reload()}} key={i} className={`right-side-links w-100 d-flex justify-content-between align-items-center ${isActive(`/tutorials/detail/${tutorialSlug}/${chapter?.slug}-${chapter?.id}`) ? 'active' : ''}`}>{chapter?.title}
            {
              showArrow?<i className='fa fa-angle-right ml-3' style={{opacity:.6}}></i>:null
            }
            
        </Link>)
        }
        
       
    </div>
    </>
  )
}
