import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../../Context/AuthContext";
import { Loadingicon } from "../../../AppIcons";
import { toast } from "react-toastify";
import sent_ico from "../../../assets/images/sent_ico.svg";
import { newsCommentAndReply } from "../../../api/careernews";
import { CDN_BASE_URL, formatTime } from "../../../utils/utils";

export default function LyricsComments({ cData, handleSubmitComment }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isReplyShow, setIsReplyShow] = useState(null);
    const { globalToken, user } = useAuth();
    const commentBoxRefs = useRef([]);

    useEffect(() => {
       
        if (isReplyShow !== null) {
            setTimeout(() => {
                const input = commentBoxRefs?.current[isReplyShow + 1]?.querySelector('input');
                if (input) input.focus();
            }, 0);
        }
    }, [isReplyShow]);

    const CommentBox = ({ customClass, index, placeholderTxt, isReply=false, cId=null }) => {
        const [comment, setComment] = useState('');
        const [sendCommentBtnLoader, setSendCommentBtnLoader] = useState(false);

        const sendComment = (commentId) => {
            handleSubmitComment(isReply?cId:commentId, setComment, comment, isReply, setSendCommentBtnLoader, setIsReplyShow);
            // setSendCommentBtnLoader(true);
            // try {
            //     const resCommentCommit = await newsCommentAndReply({ token: globalToken, newsId: cData?.id, commentId: isReply?cId:commentId, comment });
            //     // Optionally clear comment input after sending
            //     if(resCommentCommit?.success){
            //         setIsReplyShow(false)
            //         toast.success(resCommentCommit?.message);
            //         commentsListUpdate(cData?.id)
            //     }
            //     setComment('');
                
            // } catch (error) {
            //     toast.error('Error sending comment');
            // } finally {
            //     setSendCommentBtnLoader(false);
            // }
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter' && comment?.length) {
                e.preventDefault();
                sendComment(null);
            }
        };

        const handleInputChange = (e) => {
            setComment(e.currentTarget.value);
        };

        return (
            <div
                className={`col d-flex align-items-center pb-3 ${customClass}`}
                ref={el => commentBoxRefs.current[index] = el} // Assign ref
            >
                <div className="d-flex">
                     {
                                user?.profile_image?
                                <img
                                src={user?.profile_image}
                                width="40"
                                height="40"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 40 }}
                            />:<img
                            src={require(`../../../assets/images/profile_placeholder.png`)}
                            width="40"
                            height="40"
                            className="siderbarIco"
                            style={{ borderRadius: 100, height: 40 }}
                        />
                            }
                   
                </div>
                <div className="ms-2 postCommentFrm">
                    <input
                        type="text"
                        tabIndex="1"
                        className="commentInput"
                        placeholder={placeholderTxt}
                        value={comment}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    {sendCommentBtnLoader ? (
                        <div className="h-5 w-5 theme-color">
                            <Loadingicon />
                        </div>
                    ) : 
                       <> {
                            comment?.length>0?<img
                            role="button"
                            src={sent_ico}
                            onClick={() => sendComment(null)}
                            width={18}
                            height={18}
                        />:null
                        }</>
                    }
                </div>
            </div>
        );
    };

    return (
        <div className="col-12 px-0">
            <CommentBox placeholderTxt="Write a comment..." />
            <hr className="mt-2 mb-3" style={{ borderColor: '#ccc' }} />

            <div className="comments-all">
                { cData?.comments?.length?cData?.comments?.map((comment,index) => (
                    <>
                    <div className="d-flex mt-2" key={index}>
                        <div style={{ minWidth: 28, height: 28 }}>
                            {
                                comment?.user[0]?.image?
                                <img
                                src={comment?.user[0]?.image.startsWith('http://') || comment?.user[0]?.image.startsWith('https://') ?comment?.user[0]?.image:CDN_BASE_URL + comment?.user[0]?.image}
                                width="28"
                                height="28"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 28 }}
                            />:<img
                            src={require(`../../../assets/images/profile_placeholder.png`)}
                            width="28"
                            height="28"
                            className="siderbarIco"
                            style={{ borderRadius: 100, height: 28 }}
                        />
                            }
                            
                        </div>
                        <div
                            className={`ms-3 rounded d-flex flex-column chatbubble p-2 mb-2 ${isReplyShow === index ? 'w-100' : ''}`}
                        >
                            <span className="message-box text-black" style={{ fontWeight: 600 }}>{comment?.user[0]?.name}</span>
                            <span>{comment?.comment}</span>
                            <div className="col-12 my-2 px-0 border-top"></div>
                            <div
                                className="d-flex re justify-content-between align-items-center small text-black-50"
                                style={{ fontSize: 11 }}
                            >
                                {formatTime(comment)}
                                <span
                                    className="button font-bold"
                                    role="button"
                                    onClick={() => setIsReplyShow(isReplyShow === index ? null : index)}
                                >
                                    <strong>Reply</strong>
                                </span>
                            </div>
                            {isReplyShow === index ? <CommentBox customClass="reply-class pt-3" isReply={true} cId={comment?.id} placeholderTxt={`Reply To ${comment?.user[0]?.name}`}  index={index + 1} /> : null}
                        </div>
                    </div>
                    {
                        comment.subcomment?.length?comment.subcomment?.map((sComment, sIndex)=>(
                            <div className="d-flex mt-2" style={{ marginLeft: 28 }}>
                                <div style={{ minWidth: 28, height: 28 }}>
                                        {
                                            sComment?.user[0]?.image?
                                            <img
                                                src={sComment?.user[0]?.image.startsWith('http://') || sComment?.user[0]?.image.startsWith('https://') ?sComment?.user[0]?.image:CDN_BASE_URL + sComment?.user[0]?.image}
                                                width="28"
                                                height="28"
                                                className="siderbarIco"
                                                style={{ borderRadius: 100, height: 28 }}
                                            />:<img
                                                src={require(`../../../assets/images/profile_placeholder.png`)}
                                                width="28"
                                                height="28"
                                                className="siderbarIco"
                                                style={{ borderRadius: 100, height: 28 }}
                                            />
                                        }
                                    
                                </div>
                                <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2">
                                    <span className="message-box text-black" style={{ fontWeight: 600 }}>{sComment?.user[0]?.name}</span>
                                    <span><strong>{`@${sComment?.user[0]?.name} `}</strong>{sComment?.comment}</span>
                                    <div className="col-12 my-2 px-0 border-top"></div>
                                    <div className="d-flex justify-content-between align-items-center small text-black-50" style={{ fontSize: 11 }}>
                                        {formatTime(sComment)}
                                    </div>
                                </div>
                            </div>
                        )):null
                    }
                    
                    </>
                )):null}
                
                
            </div>
        </div>
    );
}
