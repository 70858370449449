import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, stripTags, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { Editor } from "@tinymce/tinymce-react";
function EditQuestion() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [catId, setCatId] = useState(null);
    const [sCatId, setSCatId] = useState();
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isSubCategoryLoading, setIsSubCategoryLoading] = useState(false);
    const { globalToken } = useAuth();
    const [categoryFormatted, setCategoryFormatted] = useState([]);
    const [subCategoryFormatted, setSubCategoryFormatted] = useState([]);
    const [defaultSCat, setDefaultSCat] = useState({});
    const [defaultCat, setDefaultCat] = useState({});
    const [sfValue, setSfValue] = useState(null);
    const [choosenQuestion, setChoosenQuestion] = useState(JSON.parse(localStorage.getItem('f__e') || '{}'));
    const location = useLocation()


    const DisplayingErrorMessagesSchema = Yup.object().shape({
        question: Yup.string().required("Question is required"),
        cat_id: Yup.string().required("Category is required"),
        scat_id: Yup.string().required("Sub Category is required")
    });
    const DisplayingErrorMessagesSchemaSec = Yup.object().shape({
        question: Yup.string().required("Question is required"),
        cat_id: Yup.string().required("Category is required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        if(choosenQuestion){
        handleFetchAllForumSubject(0);
        }
        // setChoosenQuestion(JSON.parse(localStorage.getItem('f__e') || '{}'));
        return () => {
              
              localStorage.removeItem('f__e'); 
            }
           
    }, [])

    const handleFetchAllForumSubject = async (pageNo) => {
        const resAllSubjects = await getForumSubject({ pageNo });
        if (resAllSubjects?.success) {
            const forumCat = resAllSubjects?.data?.category;
            if (forumCat?.length) {
                const cData = forumCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setCategoryFormatted(cData);
                if(choosenQuestion?.cat_id && cData?.length){
                findChoosenCat(choosenQuestion?.cat_id, cData);
                handleFetchAllForumTopic(0, choosenQuestion?.cat_id);
                }

            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }


    // fetch all topic by categry
    const handleFetchAllForumTopic = async (pageNo, forumCatId) => {
        const resallTopics = await getForumTopic({ pageNo, forumCatId });
        if (resallTopics?.success) {
            const forumTopic = resallTopics?.data?.Sub_category;
            if (forumTopic?.length) {
                const sCData = forumTopic?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setSubCategoryFormatted(sCData);
                if(choosenQuestion?.cat_id && choosenQuestion?.scat_id){
                    findChoosenSubCat(choosenQuestion?.scat_id, sCData);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }

    const formData = [
        {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick one from the list',
            required: true,
            editable: true,
            isCategory: true
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: '',
            required: true,
            editable: true,
            isSCategory: true

        },
        {
            id: 'question',
            title: 'Question',
            type: 'text',
            name: 'question',
            placeholder: 'Put your question here',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleUpdateQuestion = async (values) => {
        setBtnLoading(true);
        const resPostQuestion = await postAForumQuestion({token:globalToken, question:values?.question, cat_id:values?.cat_id, scat_id:values?.scat_id, type:'edit', id:choosenQuestion?.id});
        if(resPostQuestion?.success){
            toast.success('Question updated successfully.');
            navigate('/forum/my-forum');
        }else{
            toast.error(resPostQuestion?.errors[0])
        }
        setBtnLoading(false)
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {

        if (type == 'category') {
            setSfValue(setFieldValue);
            setSubCategoryFormatted([]);
            setDefaultSCat(null);
            setFieldValue('scat_id', null);

            if (value) {
                handleFetchAllForumTopic(0, value)
                setCatId(value);
                setFieldValue('cat_id', value);
            } else {
                setCatId(null);
                setFieldValue('cat_id', null);
                // setCategoryFormatted([]);
            }
            setSCatId(null);

        }
        if (type == 'sCategory') {
            if (value) {
                findChoosenSubCat(value);
                setSCatId(value);
                setFieldValue('scat_id', value);
            } else {
                setSCatId(null);
                setDefaultSCat(null);
                setFieldValue('scat_id', null);
            }

        }

    }

    const findChoosenSubCat = (choosenSCat, sCData) => {
        const cSCat = sCData?.filter(sc => sc?.value == choosenSCat);
        setDefaultSCat(cSCat);
    }

    const findChoosenCat = (choosenCat, cData) => {        
        const cCat = cData?.filter(sc => sc?.value == choosenCat);
     
        setDefaultCat(cCat);
    }


    return (
        <>
            {/* <BreadCrumbs /> */}
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/forum' style={{ fontSize: 14, textDecoration: 'none' }}>Forum</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/forum/my-forum' style={{ fontSize: 14, textDecoration: 'none' }}>My Forum</NavLink>
                            </li>    
                            {
                                choosenQuestion? <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                                {stripTags(choosenQuestion?.question)}
                            </li>:null
                            }                        
                           
                            <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                                Edit
                            </li>
                        </ol>
                    </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                question: choosenQuestion?.question,
                                cat_id: choosenQuestion?.cat_id,
                                scat_id: choosenQuestion?.scat_id
                            }}
                            validationSchema={sCatId ? DisplayingErrorMessagesSchemaSec : DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (values)
                                    handleUpdateQuestion(values)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map(fieldMeta => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                            <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    initialValue={choosenQuestion?.question}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                    />
                                                                {/* <textarea type={fieldMeta?.type}

                                                                    name={fieldMeta?.name}
                                                                    autoComplete={'off'}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id={fieldMeta?.name} rows={6} placeholder={fieldMeta?.placeholder} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 w-100 focus:border-primary-600 block w-full p-2.5">{choosenQuestion?.question}</textarea> */}
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isCategory ?
                                                                <div className="w-100" style={{zIndex:101, position:'relative'}}>

                                                                    <div className="d-block w-100">
                                                                        {
                                                                            <CustomSelect options={categoryFormatted} 
                                                                            defaultOption={defaultCat}
                                                                            isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'category'} isSelectLoading={isCategoryLoading} placeholder={'Category'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : fieldMeta.isSCategory ?
                                                                    <div className="w-100" style={{zIndex:100, position:'relative'}}>


                                                                        <div className="d-block w-100">
                                                                            <CustomSelect
                                                                                options={subCategoryFormatted}
                                                                                defaultOption={defaultSCat}
                                                                                isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'sCategory'} isSelectLoading={isSubCategoryLoading} placeholder={'Sub Category'} />
                                                                        </div>

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div> : null}

                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default EditQuestion;
