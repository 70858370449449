import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate } from 'react-router-dom';
import { getBlogCategory } from '../../../../api/blog';
import BlogCatSubCatList from '../common/catsubcat';
function BlogCategory() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const navigation = useNavigate();


    useEffect(() => {
        handleFetchAllBlogCategory(1);
    }, [])

    // fetch all category
    const handleFetchAllBlogCategory = async (pageNo) => {
        const resAllBlogCategory = await getBlogCategory({ pageNo: pageNo });
        if (resAllBlogCategory?.success) {
            setIsNotFound(false)
            resAllBlogCategory?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            // console.log(resAllBlogCategory);
            if (resAllBlogCategory?.data?.data?.length && pageNo == 1) {
                setAllContent(resAllBlogCategory?.data?.data);
            } else if (resAllBlogCategory?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllBlogCategory?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more streams data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllBlogCategory(nxtPage);
        setPageNo(nxtPage);
    }






    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong> Popular Categories ${yearSatra()}</strong>`} subTitle={`Explore Most Asked Questions and Post Answers`} />
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/my-blogs')}
                            >
                                My Blogs
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/post-a-blog')}
                            >
                            Post A Blog
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <BlogCatSubCatList content={content} boxSize='col-md-3 mb-3' key={sIndex} type="content" redirectionFn={() => navigation(`/blogs/${content?.slug}`)} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default BlogCategory;