import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getEducatorsByTypeWise } from '../../../../api/educator';
import EducatorListItem from '../common/educatorlist';
function EducatorsList() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [typeWiseSlug, setTypeWiseSlug] = useState();
    const [filterType, setFilterType] = useState();
    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params?.typeWiseSlug && params?.filterType) {
            setTypeWiseSlug(params?.typeWiseSlug);
            setFilterType(params?.filterType);
            handleEducatorsByTypeWise(1, params?.filterType, params?.typeWiseSlug);
        }
    }, [])

    // fetch all educators by types(industry, state, city, locality, type)
    const handleEducatorsByTypeWise = async (pageNo, fType, tSlug) => {
        const resAllEducatorByTypeWise = await getEducatorsByTypeWise({ pageNo: pageNo, fType:fType, typeWiseSlug: tSlug });
        console.log(resAllEducatorByTypeWise);
        
        if (resAllEducatorByTypeWise?.success) {
            setIsNotFound(false)
              resAllEducatorByTypeWise?.educators?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllEducatorByTypeWise?.educators?.data?.length && pageNo == 1) {
                
                setAllContent(resAllEducatorByTypeWise?.educators?.data);
            } else if (resAllEducatorByTypeWise?.educators?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllEducatorByTypeWise?.educators?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more educators by type wise data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleEducatorsByTypeWise(nxtPage, filterType, typeWiseSlug);
        setPageNo(nxtPage);
    }

    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/educators' style={{ fontSize: 14, textDecoration: 'none' }}>Educators</NavLink>
                            </li>
                         <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.typeWiseSlug)}
                            </li>
                        </ol>
                    </nav>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 ${slugToNormal(typeWiseSlug)} Colleges/Universities/Institutes in India ${yearSatra()}: Discover Fees, Courses, Admission, Placements</strong>`} subTitle={`Find list of all top colleges in ${slugToNormal(typeWiseSlug)}. Check Fees, Admission process, and Placements. This list will help you in making decisions regarding the right educator selection for your career.`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <EducatorListItem educatorData={content} filterType={filterType} redirectionFn={() => navigation(`/educators/university/${content?.slug}`)} key={sIndex} stripBottom={true} />
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default EducatorsList;