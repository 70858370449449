import React from 'react';
import { CDN_BASE_URL, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import blogsThumbPlaceholder from '../../../../assets/images/blog_thumb.png';
import { Link, useNavigate } from 'react-router-dom';

export default function EducatorListItem({ educatorData, filterType="streams", patternLinks = null, stripBottom = false, thumbPrefix = 'courses/article/', redirectionFn, type }) {
    const navigate = useNavigate();
    const thumbnail = blogsThumbPlaceholder;
    const subtitle = educatorData?.metaDescription;

    

    const popularCareerCats = [
        // {
        //     name: "CIN",
        //     value: `${educatorData?.company_reg_id}`,
        //     // redirectUrl: `/upcoming-dates`
        // },
        // {
        //     name: "Company Type",
        //     value: educatorData?.company_type,
        //     redirectUrl: `/corporates/type/${educatorData?.company_type}`
        // },
        // {
        //     name: "Location",
        //     value: {
        //         state:{
        //             name:educatorData?.state.name,
        //             redirectUrl:`/corporates/state/${educatorData?.state?.slug}`
        //         },
        //         city:{
        //             name:educatorData?.city.name,
        //             redirectUrl:`/corporates/city/${educatorData?.city?.slug}`
        //         }
        //     },
        //     type:'location'
        // }
    ];

    return (
        <div
            className="d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer"
            onClick={(e)=>redirectionFn(e)}
            style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}
        >
            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto">
                <div className="thumbnail-containers mr-3">
                    {
                        educatorData?.logo? <img
                        src={educatorData?.logo}
                        width="60"
                        height="60"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />:<span style={{width:60, height:60, background:'#ffe8d6',color:'rgb(255 156 78)', fontWeight:'bold', fontSize:'1.2rem'}} className='rounded bolder d-flex align-items-center justify-content-center' >{educatorData?.listingName?.slice(0,3)}</span>
                    }
                   
                    
                </div>

                <div className="flex-1">
                    <h6 className="mb-0 d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5">
                            <strong>{educatorData?.listingName}</strong>
                        </span>
                    </h6>

                    <div className="d-flex align-items-center">
                    <small>
                        {/* <a onClick={(e)=>{e.preventDefault();e.stopPropagation();navigate(`/corporates/industry/${educatorData?.industries[0]?.slug}`);window.location.reload();}} className="theme-a-light cursor-pointer">
                            {educatorData.industries[0].name}
                        </a> */}
                    </small>
                        {/* {tag && (
                            <>
                                <small>
                                    <a onClick={handleTagClick} className="theme-a-light cursor-pointer">
                                        {tag.name}
                                    </a>
                                </small>
                                <span className="big-dots"></span>
                            </>
                        )} */}

                        {/* <span className="span color-gray fsize-11">{nFormatter(views)} Views</span>
                        <span className="big-dots"></span>
                        <span className="span color-gray fsize-11">{nFormatter(share_count)} Shares</span>
                        <span className="big-dots"></span>
                        <span className="span color-gray fsize-11">{postingDate}</span> */}
                    </div>

                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">{subtitle}</p>
                    {/* {
                        stripBottom?<> <div> <hr style={{borderColor:"#ccc",marginTop:10,marginBottom:3}} /></div>
                        <div class="">
                            {
                                popularCareerCats?.length?popularCareerCats?.map((patternLink, pLIndex) =>(<small className='text-black-50'>
                                    <span>{patternLink?.name} : </span>
                                    {
                                        patternLink?.type=='location'?<>
                                        <><Link key={pLIndex} onClick={(e)=>{e.preventDefault();e.stopPropagation();navigate(patternLink?.value?.city.redirectUrl);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.value?.city?.name}
                                    </Link>, <Link key={pLIndex} onClick={(e)=>{e.stopPropagation();navigate(patternLink?.value?.state.redirectUrl);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.value?.state?.name}
                                    </Link></>
                                        </>:<>{
                                            patternLink?.redirectUrl?<Link key={pLIndex} onClick={(e)=>{e.preventDefault();e.stopPropagation();navigate(patternLink?.redirectUrl);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.value}
                                    </Link>:<span className='color-gray'>{patternLink?.value}</span>
}</>
                                        
                                    }
                                    {
                                        pLIndex < popularCareerCats?.length-1?<span className="big-dots"></span>:null
                                        
                                    }
                                   </small> 
                                )):null
                            }
                           
                        </div></>:null
                    } */}
                </div>
            </div>
        </div>
    );
}
