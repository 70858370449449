import React from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, nFormatter } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/c_thumb.png';

export default function StreamBranchList({ stream, boxSize='null', mH=null, redirectionFn, type, thumbPrefix=`thcn/category/thumb/`, sType=null }) {
    return (
        <>
        <div className={`${boxSize} mb-2 cursor-pointer hover-c-change`} onClick={()=>redirectionFn()} title={type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name}>
            <div className={`col-md-12 col-12 py-0 col-sm-12 left-angle-border card_box ${mH?'mh-119':'mh-93'}`}>
                <div className='d-flex align-items-center'>
                <span className="books_icon bg-transparent px-3">                    
                    {
                        // CDN_BASE_URL + `${thumbPrefix + stream?.file_name}
                        stream?.maping_id !== 13 && stream?.file_name ?<img alt={type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name} className="thumbnail rounded" width="80" height="80" src={careerThumbPlaceholder}                          
                            />:<img src={careerThumbPlaceholder}  width="80" height="80" className="feature_images rounded  overflow-hidden" alt={type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name} />
                    }             

                </span>
                <span className="w-100 d-flex flex-column">
                    <h6 className="m-0 pr-3 ellipsis-text lquestion" title={type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name}>
                        {type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name}
                    </h6>
                        <span className="span pb-1 color-gray text-capitalize fsize-11 c-a5">  
                            {
                                type=="stream"?<>{nFormatter(stream?.subcategories_count || 0)} Exams
                                <span className="big-dots"></span></>:null
                            } 
                            {
                                !sType?<>{nFormatter(stream?.total_news || stream?.news_count || 0)} News</>:<>{convertDateFormatOnly(stream?.posting_date || stream?.created_at)}</>
                            }
                        </span>
                    
                </span>
                </div>
                <i className='fa-solid fa-arrow-right-long me-2 text-black-50' style={{opacity:0.5}}></i>
            </div>
        </div>





        {/* <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`} onClick={() => redirectionFn()} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {
                        stream?.maping_id !== 13 && stream?.file_name ?
                            <img src={CDN_BASE_URL + `${thumbPrefix + stream?.file_name}`} width="80" height="80" className="rounded bg-gray" />
                            : <img src={`${careerThumbPlaceholder}`} width="80" height="80" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{type=='pattern' && !stream?.name && !stream.title?'All India':type=='pattern'?stream?.name || stream?.title:type=='cityListAdmissions'?stream?.city_name+' Admissions':type=='cityList'?stream?.city_name + ' Jobs':stream?.name}</strong> {

                        }</span>
                    </h6>


                    <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
                       {
                        type=="stream"?<><small><span>{nFormatter(stream?.subcategories_count || 0)} Exams</span></small>
                        <span className="big-dots"></span></>:null
                       } 
                       {
                        !sType?<small>{nFormatter(stream?.total_news_count || stream?.news_count || 0)} News</small>:<small>{convertDateFormatOnly(stream?.posting_date)}</small>
                       }
                        
                    </div>
                  
                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">Read latest news on {stream?.name} Exams, Syllabus, Pattern, Results, Jobs and more!</p>
                </div>
            </div>
        </div> */}
        </>
    )
}
