import React from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import blogsThumbPlaceholder from '../../../../assets/images/blog_thumb.png';
import { Link, useNavigate } from 'react-router-dom';

export default function BlogListItem({ blogData, handleDeleteModal=null, patternLinks=null, stripBottom=false, thumbPrefix='courses/article/', redirectionFn, type, isEdit=false }) {

    const navigation = useNavigate();
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`}  onClick={(e) => redirectionFn(e)} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {
                        blogData?.image ?
                        <img
                        src={
                            CDN_BASE_URL +
                            `${blogData?.image}`
                        }
                        width="90"
                        height="90"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />
                            : <img src={`${blogsThumbPlaceholder}`} width="90" height="90" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{blogData?.title}</strong> {

                        }</span>
                    </h6>
                    <span className='w-100 d-flex align-items-center'>
                    

                       <small><a onClick={(e) => {e.stopPropagation();e.preventDefault();navigation(`/blogs/${blogData?.cat?.slug}`)}} className="theme-a-light cursor-pointer">{blogData?.cat?.name}</a></small>
                        <span className="big-dots"></span>
                        <small><a onClick={(e) => {e.stopPropagation();e.preventDefault(); navigation(`/blogs/topic/${blogData?.scat?.slug}`)}} className="theme-a-light cursor-pointer">{blogData?.scat?.name}</a></small>
                        <span className="big-dots"></span>
                        
                    <span className='span color-gray text-capitalize fsize-11 c-a5'>{nFormatter(blogData?.views)} Views</span>
                        <span className="big-dots"></span>
                        <span className='span color-gray text-capitalize fsize-11 c-a5'>{nFormatter(blogData?.share_count)} Shares</span>
                        <span className="big-dots"></span>
                    <span className='span color-gray text-capitalize fsize-11 c-a5'>{convertDateFormatOnly(blogData?.posting_date || blogData?.created_at)}</span>
                    {
                            isEdit?<><span className="big-dots"></span>
                        {
                            blogData?.status==0?<span className="span text-warning fsize-11">{'Approval Pending'}</span>:<span className="span text-success fsize-11">{'Approved'}</span>
                        }</>:null
                        }
                    </span>
                   
                    {/* <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
                        <small>{nFormatter(blogData?.news_count || 0)} News dangerouslySetInnerHTML={{__html:`${blogData?.about}`}}</small>
                    </div> */}
                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp" >{limitSubtitle(stripTags(blogData?.about || blogData?.description || blogData?.other), 290) }</p>
                    {
                        stripBottom?<> <div> <hr style={{borderColor:"#ccc",marginTop:10,marginBottom:3}} /></div>
                        <div className="">
                            {
                                patternLinks?.length?patternLinks?.map((patternLink, pLIndex) =>(<small>
                                    <Link key={pLIndex} onClick={(e)=>{e.preventDefault();e.stopPropagation();navigation(`/career-news/news/${blogData?.id}/${blogData?.scat?.id}/${blogData?.scat?.slug}${patternLink?.redirectUrl}`);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.name}
                                    </Link>
                                    {
                                        pLIndex < patternLinks?.length-1?<span className="big-dots"></span>:null
                                        
                                    }
                                   </small> 
                                )):null
                            }
                           
                        </div></>:null
                    }
                   
                </div>
                {
                        isEdit?<>
                        <button className='btn bg-none text-theme-color' onClick={(e)=>{e.preventDefault();e.stopPropagation();handleDeleteModal(blogData)}} title='Delete' type='button'><i className='fas fa-trash'></i></button>
                        <button className='btn bg-none text-theme-color' onClick={(e)=>{localStorage.setItem('b__e', JSON.stringify(blogData));e.preventDefault();e.stopPropagation();navigation('/blogs/my-blogs/edit-blog')}} title='Edit' type='button'><i className='fas fa-pencil'></i></button>
                        </>:null
                       } 
            </div>
        </div>
    )
}
