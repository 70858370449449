import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../../Context/AuthContext";
import { Loadingicon } from "../../../AppIcons";
import { toast } from "react-toastify";
import sent_ico from "../../../assets/images/sent_ico.svg";
import { newsCommentAndReply } from "../../../api/careernews";
import { CDN_BASE_URL, formatTime, nFormatter } from "../../../utils/utils";

export default function ForumAnswers({ cData, handleLikeUnlikeDislikeAnswer, handleSubmitComment, answerCount, modifyAnchorTags }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isReplyShow, setIsReplyShow] = useState(null);
    const { globalToken, user } = useAuth();
    const commentBoxRefs = useRef([]);

    useEffect(() => {

        if (isReplyShow !== null) {
            setTimeout(() => {
                const input = commentBoxRefs?.current[isReplyShow + 1]?.querySelector('input');
                if (input) input.focus();
            }, 0);
        }
    }, [isReplyShow]);

    const CommentBox = ({ customClass, index, placeholderTxt, isReply = false, cId = null }) => {
        const [comment, setComment] = useState('');
        const [sendCommentBtnLoader, setSendCommentBtnLoader] = useState(false);

        const sendComment = (commentId) => {
            handleSubmitComment(isReply ? cId : commentId, setComment, comment, isReply, setSendCommentBtnLoader, setIsReplyShow);

        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter' && comment?.length) {
                e.preventDefault();
                sendComment(null);
            }
        };

        const handleInputChange = (e) => {
            setComment(e.currentTarget.value);
        };

        return (
            <div
                className={`col d-flex align-items-center pb-3 ${customClass}`}
                ref={el => commentBoxRefs.current[index] = el} // Assign ref
            >
                <div className="d-flex">
                    {
                        user?.profile_image ?
                            <img
                                src={user?.profile_image}
                                width="40"
                                height="40"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 40 }}
                            /> : <img
                                src={require(`../../../assets/images/profile_placeholder.png`)}
                                width="40"
                                height="40"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 40 }}
                            />
                    }

                </div>
                <div className="ms-2 postCommentFrm" style={{ border: '1px solid #f0f0f0', background: 'white' }}>
                    <input
                        type="text"
                        tabIndex="1"
                        className="commentInput"
                        placeholder={placeholderTxt}
                        value={comment}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    {sendCommentBtnLoader ? (
                        <div className="h-5 w-5 theme-color">
                            <Loadingicon />
                        </div>
                    ) :
                        <> {
                            comment?.length > 0 ? <img
                                role="button"
                                src={sent_ico}
                                onClick={() => sendComment(null)}
                                width={18}
                                height={18}
                            /> : null
                        }</>
                    }
                </div>
            </div>
        );
    };


    const AnswerComponent = ({ comment, index }) => {
        
    const [downvoteAnsLoader, setDownvoteAnsLoader] = useState(false);
    const [upvoteAnsLoader, setUpvoteAnsLoader] = useState(false);
        return (
            <div className="d-flex mt-4">
                <div style={{ minWidth: 28, height: 28 }}>
                    {
                        comment?.user?.image ?
                            <img
                                src={comment?.user?.image.startsWith('http://') || comment?.user?.image.startsWith('https://') ? comment?.user?.image : CDN_BASE_URL + comment?.user?.image}
                                width="28"
                                height="28"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 28 }}
                            /> : <img
                                src={require(`../../../assets/images/profile_placeholder.png`)}
                                width="28"
                                height="28"
                                className="siderbarIco"
                                style={{ borderRadius: 100, height: 28 }}
                            />
                    }

                </div>
                <div
                    style={{ background: 'none' }}
                    className={`ms-3 rounded d-flex flex-column flex-1 chatbubble p-2 pt-0 mb-2 ${isReplyShow === index ? 'w-100' : ''}`}
                >
                    <span className="message-box text-black d-flex align-items-center" style={{ fontWeight: 600, lineHeight: 'initial' }}>{comment?.user?.first_name}{comment?.user?.last_name ? ' ' + comment?.user?.last_name : ''}
                        <span className='big-dots'></span>
                        <small className="text-black-50" style={{fontWeight:100}}>{formatTime(comment)}</small>
                    </span>
                    <span className="span-answer-container">
                        <span className="span-answer" dangerouslySetInnerHTML={{ __html: `${comment?.answer?modifyAnchorTags(comment?.answer?.replace(/<p>&nbsp;<\/p>/g, '')):''}` }}></span>
                        <div className="col-12 my-2 px-0 border-tops bt"></div>
                        <div
                            className="d-flex re w-100 justify-content-between align-items-center small text-black-50"
                            style={{ fontSize: 11 }}
                        >
                            <span className="vote-upvote-answer-container d-flex align-items-center">
                                <span className="for-upvote d-flex align-items-center" onClick={() =>
                                    handleLikeUnlikeDislikeAnswer(comment?.is_user_like ? "unlike" : "like", 'lke', comment?.id, setDownvoteAnsLoader, setUpvoteAnsLoader)
                                }>
                                    {
                                        upvoteAnsLoader ? <span className="custom-loader-small ms-0 me-2 theme-color">
                                            <Loadingicon />
                                        </span> : <><i
                                            className={`fas fa-thumbs-up cursor-pointer ms-0 me-2 ${comment?.is_user_like && "text-themecolor"
                                                }`}

                                        ></i>{" "}</>
                                    }
                                    {/* <i className="fas fa-thumbs-up"></i> */}
                                    <strong>{nFormatter(comment?.user_liked_count || 0)}</strong>
                                </span>

                                <span className="for-downvote d-flex align-items-center" onClick={() =>
                                    handleLikeUnlikeDislikeAnswer(comment?.is_user_dislike ? "rmdislike" : "dislike", 'dLike', comment?.id, setDownvoteAnsLoader, setUpvoteAnsLoader)
                                }>
                                    {
                                        downvoteAnsLoader ? <span className="custom-loader-small ms-0 me-2 theme-color">
                                            <Loadingicon />
                                        </span> : <><i
                                            className={`fas fa-thumbs-down cursor-pointer ms-0 me-2 ${comment?.is_user_dislike && "text-themecolor"
                                                }`}

                                        ></i>{" "}</>
                                    }
                                    {/* <i className="fas fa-thumbs-down"></i>  */}
                                    <strong>{nFormatter(comment?.user_disliked_count || 0)}</strong>
                                </span>
                            </span>

                            <span
                                className="button font-bold"
                                role="button"
                                onClick={() => setIsReplyShow(isReplyShow === index ? null : index)}
                            >
                                <strong style={{ fontSize: 13 }}>Reply</strong>
                            </span>
                        </div>
                        {isReplyShow === index ? <CommentBox customClass="reply-class pt-3" isReply={true} cId={comment?.id} placeholderTxt={`Reply To ${comment?.user?.first_name} ${comment?.user?.last_name}`} index={index + 1} /> : null}
                        {
                            comment.replies?.length ? (
                                <>
                                    <br />
                                    {comment.replies.map((sComment, sIndex) => (
                                        <div key={sIndex} className="d-flex mt-3">
                                            <div style={{ minWidth: 28, height: 28 }}>
                                                {
                                                    sComment?.user?.image ? (
                                                        <img
                                                            src={sComment?.user?.image.startsWith('http://') || sComment?.user?.image.startsWith('https://')
                                                                ? sComment?.user?.image
                                                                : `${CDN_BASE_URL}${sComment?.user?.image}`}
                                                            width="28"
                                                            height="28"
                                                            className="siderbarIco"
                                                            style={{ borderRadius: 100, height: 28 }}
                                                            alt="User"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={require('../../../assets/images/profile_placeholder.png')}
                                                            width="28"
                                                            height="28"
                                                            className="siderbarIco"
                                                            style={{ borderRadius: 100, height: 28 }}
                                                            alt="Placeholder"
                                                        />
                                                    )
                                                }
                                            </div>
                                            <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2" style={{ background: 'none' }}>
                                                <span className="message-box mb-2 text-black d-flex align-items-center" style={{ fontWeight: 600, lineHeight: 'initial' }}>
                                                    {sComment?.user?.first_name}
                                                    {sComment?.user?.last_name ? ` ${sComment?.user?.last_name}` : 'TuteeHUB'}
                                                    <span className="big-dots"></span>
                                                    <small className="text-black-50" style={{fontWeight:100}}>{formatTime(sComment)}</small>
                                                </span>
                                                <span>
                                                    <strong>
                                                        {`@${comment?.user?.first_name}${comment?.user?.last_name ? ` ${comment?.user?.last_name}` : ''}`}
                                                    </strong>
                                                    <span className="ms-1" dangerouslySetInnerHTML={{ __html: sComment?.answer }}></span>
                                                </span>
                                                {/* <div className="col-12 my-2 px-0 border-top"></div> */}
                                                <div className="d-flex justify-content-between align-items-center small text-black-50" style={{ fontSize: 11 }}>
                                                    {/* Additional content can go here */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : null
                        }

                    </span>
                </div>
            </div>

        )
    }
        return (
            <div className="col-12 px-0 ">
                <CommentBox placeholderTxt="Write your answer..." />
                {/* <hr className="mt-2 mb-3" style={{ borderColor: '#ccc' }} /> */}
                <div className="heading-main position-relative my-3 overflow-hidden">
                    <h5 className="text-left bg-white pr-3 b text-black" style={{ background: '#f3eee62e' }}><strong>Answers ({answerCount || 0})</strong></h5>
                    <hr className="heading-hr mb-0" />
                </div>
                <div className="comments-all">
                    {Object.values(cData?.answers).length ? Object.values(cData?.answers)?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((comment, index) => (
                        <>
                            <AnswerComponent comment={comment} index={index} key={index + 'ans'} />

                        </>
                    )) : null}


                </div>
            </div>
        );
    }
