import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, slugToNormal, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { getBlogCategory, getBlogSubCategory, postBlog } from "../../../../api/blog";
import { Editor } from "@tinymce/tinymce-react";
import { getTutorialCategory, postTutorial } from "../../../../api/tutorial";
function EditTutorial() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [tagId, setTagId] = useState(null);
    const [isTagLoading, setIsTagLoading] = useState(false);
    const { globalToken } = useAuth();
    const [tagFormatted, setTagFormatted] = useState([]);
    const [defaultTag, setDfaultTag] = useState([]);
    const [choosenTutorial, setChoosenTutorial] = useState(JSON.parse(localStorage.getItem('t__e') || '{}'));
    const location = useLocation()


    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        about: Yup.string().required("About Author is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (choosenTutorial) {
            setDfaultTag([{label:choosenTutorial?.tag?.name, value:choosenTutorial?.tag?.id}]);
            handleFetchAllTutorialTags(1);
        }
        return () => {
            localStorage.removeItem('t__e');
        }

    }, [])

    const handleFetchAllTutorialTags = async (pageNo) => {
        const resAllTutorialTag = await getTutorialCategory({ pageNo: pageNo });
        if (resAllTutorialTag?.success) {
            const tutorialTag = resAllTutorialTag?.data?.data;
            if (tutorialTag?.length) {
                const cData = tutorialTag?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];


                setTagFormatted(cData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
    }


    

    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'Blog Title',
            required: true,
            editable: true
        },
        {
            id: 'tag',
            title: 'Tag',
            type: 'text',
            name: 'tag',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isTag: true
        },
        {
            id: 'about',
            title: 'About Author',
            type: 'text',
            name: 'about',
            placeholder: 'About author',
            required: true,
            editable: true,
            width: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Description',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleUpdateTutorial = async (values) => {
        setBtnLoading(true);

        const resPostTutorial = await postTutorial({ token: globalToken, title: values?.title, description: values?.description, tag: values?.tag, type: 'save', image: values?.image, about: values?.about, id: choosenTutorial?.id });
        if (resPostTutorial?.success) {
            toast.success('Tutorial posted successfully.');
            navigate('/tutorials/my-tutorials')
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false)
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        if (type == 'tag') {
            if (value) {
                // console.log('v', value)
                setTagId(value);
                setFieldValue('tag', value);
                findChoosenTag(value, tagFormatted)
            } else {
                setTagId(null);
                setFieldValue('tag', null);
            }
        }

    }


    const findChoosenTag = (choosenTag, cData) => { 
        // const cTag = cData?.filter(sc => sc?.value == choosenTag);  
        // console.log(choosenTag)   
        setDfaultTag(choosenTag);
    }

    return (
        <>
            {/* <BreadCrumbs /> */}
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials/my-tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>My Tutorials</NavLink>
                    </li>
                    {
                        choosenTutorial ? <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                            {choosenTutorial?.title}
                        </li> : null
                    }

                    <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                        Edit
                    </li>
                </ol>
            </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: choosenTutorial?.title,
                                description: choosenTutorial?.description,
                                tag: [{label:choosenTutorial?.tag?.name, value:choosenTutorial?.tag?.id}],
                                about: choosenTutorial?.about
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (values)
                                    handleUpdateTutorial(values)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map(fieldMeta => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    initialValue={fieldMeta?.name == "description" ? choosenTutorial?.description : choosenTutorial?.about}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                />
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isTag ?
                                                                <div className="w-100" style={{zIndex:100, position:'relative'}}>

                                                                    <div className="d-block w-100">
                                                                        {
                                                                            <CustomSelect options={tagFormatted}
                                                                                defaultOption={defaultTag}
                                                                                isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'tag'} isSelectLoading={isTagLoading} placeholder={'Choose among tags'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : <>
                                                                    <input
                                                                        disabled={!fieldMeta?.editable}
                                                                        type={fieldMeta?.type}
                                                                        name={fieldMeta?.id}
                                                                        autoComplete={'off'}
                                                                        id={fieldMeta?.name}
                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                        placeholder={fieldMeta?.title}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values[fieldMeta?.id] || ""}
                                                                    />
                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </>}

                                                </div>
                                            ))
                                        }
                                        {
                                            choosenTutorial?.image ? <div className="col-md-1 mb-3">
                                                <div className="image-container rounded">
                                                    <img src={CDN_BASE_URL + '' + choosenTutorial?.image} alt="blog image" className="rounded" />

                                                </div>
                                            </div> : ''
                                        }

                                        <div className="col-md-11 mb-3">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500" >Set Blog Image (Supported Formats JPG,PNG,BMP,WEBP)</label>
                                            <input type="file" onChange={(event) => {
                                                setFieldValue('image', event.currentTarget.files[0]);
                                            }}
                                                onBlur={handleBlur}
                                                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                name="image" />
                                        </div>

                                    </div>
                                    <div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Save"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default EditTutorial;
