import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ForumListItem from '../common/forumlistitem';
import { getForumList } from '../../../../api/forum';
function ForumList() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [forumSubCatSlug, setForumSubCatSlug] = useState();
    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params?.forumCatSlug && params?.forumSubCatSlug) {
            setForumSubCatSlug(params?.forumSubCatSlug);
            handleForumListBySCat(1, params?.forumSubCatSlug);
        }
    }, [])

    // fetch all forum list by sub category;
    const handleForumListBySCat = async (pageNo, fSCatSlug) => {
        const resForumList = await getForumList({ pageNo: pageNo, forumSubCatSlug: fSCatSlug });
        console.log(resForumList);
        
        if (resForumList?.success) {
            setIsNotFound(false)
              resForumList?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resForumList?.data?.data?.length && pageNo == 1) {
                
                setAllContent(resForumList?.data?.data);
            } else if (resForumList?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resForumList?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more forum by sub category
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleForumListBySCat(nxtPage, forumSubCatSlug);
        setPageNo(nxtPage);
    }

    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/forum' style={{ fontSize: 14, textDecoration: 'none' }}>Forum</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/forum/${params?.forumCatSlug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{slugToNormal(params?.forumCatSlug)}</NavLink>                            
                            </li>                            
                             <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                                {slugToNormal(params.forumSubCatSlug)}
                            </li>
                        </ol>
                    </nav>
                   
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top ${slugToNormal(forumSubCatSlug)} Forum Discussions in ${yearSatra()} : Connect, Learn and Collaborate</strong>`} subTitle={`Our Top 10 ${slugToNormal(forumSubCatSlug)} Forum Discussion Listing offers a wide array of topics within the ${slugToNormal(params?.forumCatSlug)} domain, for diverse interests and expertise levels. From beginners seeking guidance to seasoned professionals looking for advanced insights, you'll find a wealth of discussions to learn and expand your understanding.`} />
                        <div className='btn-group question-box question-box-card mw-220'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/forum/my-forum')}
                            >
                                My Forum
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/forum/post-a-question')}
                            >
                            Post A Question
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <ForumListItem forumData={content} redirectionFn={() => navigation(`/forum/${params?.forumCatSlug}/${params?.forumSubCatSlug}/${content?.slug}`)} key={sIndex} />
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default ForumList;