import React from 'react';
import { CDN_BASE_URL, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import blogsThumbPlaceholder from '../../../../assets/images/blog_thumb.png';
import { Link, useNavigate } from 'react-router-dom';

export default function TutorialListItem({ tutorialData, handleDeleteModal=null, patternLinks = null, stripBottom = false, thumbPrefix = 'courses/article/', redirectionFn, type, isEdit=false }) {
    const navigate = useNavigate();
    const { image, title, tag, views, share_count, posting_date, created_at, about, description, other, id, scat } = tutorialData || {};
    const thumbnail = image ? `${CDN_BASE_URL}${image}` : blogsThumbPlaceholder;
    const postingDate = convertDateFormatOnly(posting_date || created_at);
    const subtitle = limitSubtitle(stripTags(about || description || other), 290);

    const handleTagClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/tutorials/${tag?.slug}`);
        window.location.reload();
    };

    const handlePatternLinkClick = (e, patternLink) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/career-news/news/${id}/${scat?.id}/${scat?.slug}${patternLink?.redirectUrl}`);
        window.location.reload();
    };

    return (
        <div
            className="d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer"
            onClick={redirectionFn}
            style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}
        >
            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto">
                <div className="thumbnail-containers mr-3">
                    <img
                        src={thumbnail}
                        width="90"
                        height="90"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />
                </div>

                <div className="flex-1">
                    <h6 className="mb-0 d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5">
                            <strong>{title}</strong>
                        </span>
                    </h6>

                    <div className="d-flex align-items-center">
                        {tag && (
                            <>
                                <small>
                                    <a onClick={handleTagClick} className="theme-a-light fsize-11 cursor-pointer">
                                        {tag.name}
                                    </a>
                                </small>
                                <span className="big-dots"></span>
                            </>
                        )}

                        <span className="span text-black-50 fsize-11">{nFormatter(views)} Views</span>
                        <span className="big-dots"></span>
                        <span className="span text-black-50 fsize-11">{nFormatter(share_count)} Shares</span>
                        <span className="big-dots"></span>
                        <span className="span text-black-50 fsize-11">{postingDate}</span>
                        {
                            isEdit?<><span className="big-dots"></span>
                        {
                            tutorialData?.status==0?<span className="span text-warning fsize-11">{'Approval Pending'}</span>:<span className="span text-success fsize-11">{'Approved'}</span>
                        }</>:null
                        }
                        
                    </div>

                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">{subtitle}</p>
                    {
                        stripBottom?<> <div> <hr style={{borderColor:"#ccc",marginTop:10,marginBottom:3}} /></div>
                        <small class="">
                        <Link
                             onClick={(e)=>{e.preventDefault();e.stopPropagation();localStorage.setItem('t__e', JSON.stringify(tutorialData));navigate(`/tutorials/${tutorialData?.slug}/add-chapter`)}} 
                             className='color-blue-hover'>
                                        Add Chapter
                                    </Link>
                                    {
                                        tutorialData?.chapters_count_count > 0?<> <span className="big-dots"></span>
                                        <Link
                                  onClick={(e)=>{e.preventDefault();e.stopPropagation();localStorage.setItem('t__e', JSON.stringify(tutorialData));navigate(`/tutorials/${tutorialData?.slug}/edit-chapter`)}} 
                                  className='color-blue-hover'>
                                             Edit Chapter
                                         </Link></>:null
                                    }
                                  
                           
                        </small></>:null
                    }
                </div>
                {
                        isEdit?<><button className='btn bg-none text-theme-color' onClick={(e)=>{e.preventDefault();e.stopPropagation();handleDeleteModal(tutorialData)}} title='Delete' type='button'><i className='fas fa-trash'></i></button><button className='btn bg-none text-theme-color' onClick={(e)=>{localStorage.setItem('t__e', JSON.stringify(tutorialData));e.preventDefault();e.stopPropagation();navigate('/tutorials/my-tutorials/edit-tutorial')}} title='Edit' type='button'><i className='fas fa-pencil'></i></button></>:null
                       } 
            </div>
        </div>
    );
}
