import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate, useParams } from 'react-router-dom';
import { getForumSubject } from '../../../../api/forum';
import ForumSubjectTopicItem from '../common/subjecttopic';


function ForumSubject() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allSubject, setAllSubject] = useState([]);
    const params = useParams();

    const navigation = useNavigate();

    useEffect(() => {
            handleFetchAllForumSubject(1);
       
    }, [])

    // fetch all subject
    const handleFetchAllForumSubject = async (pageNo) => {
        const resAllSubjects = await getForumSubject({ pageNo });
        if (resAllSubjects?.success) {
            const forumCat = resAllSubjects?.data?.category;
            console.log('subjects', forumCat?.data);
            setIsNotFound(false)
            forumCat?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (forumCat?.data.length && pageNo == 1) {
                setAllSubject(forumCat?.data);
            } else if (forumCat?.data?.length && pageNo !== 1) {
                setAllSubject([...allSubject, ...forumCat?.data]);
            } else {
                if (pageNo == 1) {
                    setAllSubject([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more streams data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllForumSubject(nxtPage);
        setPageNo(nxtPage);
    }

    


    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Join the Conversation : Top Knowledge Sharing Discussion Forums in ${yearSatra()}</strong>`} subTitle={`At TuteeHub , we believe that knowledge is best nurtured through active engagement and sharing. Our Forum Discussions are designed to foster a supportive environment where you can connect, learn, and network with peers who are equally passionate. Join us in building a thriving community that empowers each member to grow and excel.`} />
                        <div className='btn-group question-box question-box-card mw-220'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/forum/my-forum')}
                            >
                                My Forum
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/forum/post-a-question')}
                            >
                            Post A Question
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allSubject?.map((subject, sIndex) => (
                                <ForumSubjectTopicItem content={subject} key={sIndex} boxSize='col-md-3' type="subject" redirectionFn={()=>navigation(`/forum/${subject?.slug}`)}/>
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    
                      <PopularCareerCategories catsList={popularCareerCats} title={'Popular Categories'}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default ForumSubject;
