import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { getAllblogsCats, getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import StreamBranchList from '../common/streambranchlist';
import { useNavigate } from 'react-router-dom';


function NewsStreams() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allStreams, setAllStreams] = useState([]);

    const navigation = useNavigate();
   

    useEffect(() => {
        handleFetchAllStreams(1);
        handleFetchAllBlogs(1)
    }, [])


const handleFetchAllBlogs= async (pageNo) => {
        const res = await getAllblogsCats({ pageNo: pageNo });
        console.log(res)
}
    // fetch all streams
    const handleFetchAllStreams = async (pageNo) => {
        const resAllStreams = await getAllStreams({ pageNo: pageNo });
        if (resAllStreams?.success) {
            console.log('streams', resAllStreams?.data);
            setIsNotFound(false)
            resAllStreams?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllStreams?.data?.length && pageNo == 1) {
                setAllStreams(resAllStreams?.data);
            } else if (resAllStreams?.data?.length && pageNo !== 1) {
                setAllStreams([...allStreams, ...resAllStreams?.data]);
            } else {
                if (pageNo == 1) {
                    setAllStreams([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more streams data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllStreams(nxtPage);
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
        }
    ];


    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 Trending Careers & Streams For Session ${yearSatra()}</strong>`} subTitle={`Read 100+ news items on all career streams covering latest updates on all competitive, Public / Private Sector Job and entrance exams in ${yearSatra()}. Practice for all career entrance and job exams in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allStreams?.map((stream, sIndex) => (
                                <StreamBranchList stream={stream} boxSize='col-md-3 mb-3' key={sIndex} type="stream" redirectionFn={()=>navigation(`/career-news/top-10-trending-careers-streams/${stream?.id}/${stream?.slug}`)}/>
                            ))
                        }
                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default NewsStreams;
