import axios from "axios";
import { BLOGS_BASE_URL, CAREER_NEWS_BASE_URL } from "../../utils/utils";
import { toast } from "react-toastify";

export const getTopStreams = () => { 
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-top/stream`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllStreams = (params) => { 
  const { pageNo } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-list/stream?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getAllblogsCats = (params) => { 
  const { pageNo } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  let requestOptions = {
    method: "GET",
    // headers: myHeaders,
  };  
  return fetch(`${BLOGS_BASE_URL}blogs/category`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getAllPatternListing = (params) => { 
  const { pageNo, priority } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formData = new FormData();
  formData.append('priority', priority);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-pattern/en/view/all/list?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getAllCityListing = (params) => { 
  const { pageNo, stateId, type } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formData = new FormData();
  formData.append('state_id', stateId);
  formData.append('type', type);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-news-state-city/list?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};


export const getNewsByCity = (params) => { 
  const { pageNo, cityId, type, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");  
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  if(cityId){
  formData.append('city_id', cityId);
  }
  formData.append('type', type);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-news-by-city/list/authenticate-user?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getNewsByEducation= (params) => { 
  const { pageNo, educationId, type, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");  
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('education_id', educationId);
  formData.append('type', type);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-education/list/authenticate-user?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getTopBranches = () => { 
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-top/branches`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};


export const getAllBranches = (params) => { 
  const { pageNo, streamId } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-list/branch/${streamId}?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getBranchDetail = (params) => { 
  const { pageNo, branchId, streamId, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('stream_id', streamId);
  formData.append('branch_id', branchId);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-list/news-type?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};


export const likeUnlikeDislike = (params) => { 
  const { newsId, voteType, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('newsId', newsId);
  formData.append('voteType', voteType);
  
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}news-vote`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const newsCommentAndReply = (params) => { 
  const { newsId, comment, commentId, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('newsId', newsId);
  formData.append('comment', comment);
  formData.append('commentId', commentId || 0);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}news-comment`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const bookMarkNews = (params) => { 
  const { newsId, type, favourite, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('newsId', newsId);
  formData.append('type', type);
  formData.append('favourite', favourite);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}news-favourite`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getBookmarkedNews   = (params) => { 
  const {token, newsId, type} = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('newsId', newsId);
  formData.append('type', type);
  formData.append('favourite', 1);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}news-favourite/list/en`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getTopLatestNews = (params) => { 
    const {token} = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/latest-news-by-group/authenticate-user`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getLatestGovNews = (params) => { 
  const {token, pageNo} = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/latest-news/authenticate-user?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getTopSarkariNews = (params) => { 
    const {token, pageNo} = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/latest-sarkari-news/authenticate-user?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};


export const getTopSectionWiseNews = () => { 
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/section-news`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getTopLastDateApplyNews = (params) => { 
    const {token, pageNo} = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/lastdate-news/authenticate-user?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};



export const getTopOtherNews = (params) => { 
    const {token, pageNo} = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${CAREER_NEWS_BASE_URL}get-landing/en/other-news/authenticate-user?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};


export const getNewsByPattern = (params) => { 
  const { pageNo, branchId, streamId, token, newsPattern } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('stream_id', streamId);
  formData.append('branch_id', branchId);
  formData.append('priority', newsPattern);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-list/news-type/pattern/authenticate-user?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};


export const getNewsDetail = (params) => { 
  const {token, newsId, language} = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-news-authenticate-user/details/${newsId}/${language}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getSimilarNews = (params) => { 
  const {newsSlug, token} = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}similar-news/${newsSlug}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getNewsDetailPatternWise = (params) => { 
  const {token, subCatId, slug} = params;

  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${CAREER_NEWS_BASE_URL}get-news-by-patterns/${subCatId}/${slug}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

