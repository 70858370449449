import axios from "axios";
import { LYRICS_BASE_URLSE_URL } from "../../utils/utils";
import { toast } from "react-toastify";
import { LYRICS_BASE_URL } from "../../utils/utils";

export const getAllAlbums = (params) => { 
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/album/viewall?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllLyricists = (params) => { 
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/lyricists/viewall?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllArtists = (params) => { 
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/artists/viewall?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllLyricsByAlbum = (params) => { 
    const { pageNo, albumId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/album/lyrics/${albumId}?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllLyricsByArtist = (params) => { 
    const { pageNo, artistId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/artist/lyrics/${artistId}?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllLyricsByLyricist = (params) => { 
    const { pageNo, lyricistId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/lyricist/lyrics/${lyricistId}?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getLyricsDetail = (params) => { 
    const { lyricsId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${LYRICS_BASE_URL}get/lyrics/details/${lyricsId}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};




export const lyricsCommentAndReply = (params) => { 
    const { lyricId, comment, commentId, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('lyricId', lyricId);
    formData.append('comment', comment);
    formData.append('commentId', commentId || 0);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${LYRICS_BASE_URL}lyric/comment`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const bookMarkLyrics = (params) => { 
    const { lyricId, favourite, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('lyricId', lyricId);
    formData.append('favourite', favourite);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${LYRICS_BASE_URL}lyric-favourite/add`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const giveRatingToLyric = (params) => { 
    const { lyricId, token, rating } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('lyricId', lyricId);
    formData.append('rating', rating);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${LYRICS_BASE_URL}lyric/rating`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const reportToLyric = (params) => { 
    const { lyricId, token, report_message } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('lyricId', lyricId);
    formData.append('report_message', report_message);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${LYRICS_BASE_URL}lyric/report`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const likeUnlikeDislike = (params) => { 
    const { lyricId, voteType, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('lyricId', lyricId);
    formData.append('voteType', voteType);
    
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${LYRICS_BASE_URL}lyric/vote`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };