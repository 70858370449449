import React, { useEffect } from 'react';
import "./App.css";
import "./assets/css/styles/globals.css";
import 'bootstrap/dist/css/bootstrap.css'
import "./assets/css/styles/home.css";
import Login from "./components/auth/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OurServices from "./components/ourServices/OurServices";
import QuestionBank from "./components/ourServices/questionBank/QuestionBank";
import QuestionBankCategories from "./components/ourServices/questionBank/QuestionBankCategories";
import QuestionsSubCategories from "./components/ourServices/questionBank/QuestionsSubCategories";
import QuestionFeed from "./components/ourServices/questionBank/QuestionFeed";
import FavouriteQuestions from "./components/ourServices/questionBank/FavouriteQuestions";
import Feeds from "./components/ourServices/feeds";
import { FeedProvider } from "./Context/FeedsContext";
import FeedDetail from "./components/ourServices/feeds/feeddetail/feedDetail";
import { SearchProvider } from "./Context/SearchContext";
import Landing from "./components/landing";
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import { VerifyUserStatusProvider } from './Context/VerifyStatusContext';
import LandingScreen from './components/landingScreen.js/LandingScreen';
import Redirection from './components/redirection';
import CreditEarned from './components/ourServices/finance/Finance';
import Plans from './components/ourServices/plans/Plans';
import PaymentDetails from './components/ourServices/paymentdetails';
import MyProfile from './components/ourServices/myprofile';
import ChangePassword from './components/ourServices/changepassword';
import AskMeAnything from './components/ourServices/askmeanything';
import ProfileVerification from './components/ourServices/profileverification';
import HelpAndSupport from './components/ourServices/helpsupport';
import RaiseTicket from './components/ourServices/raiseticket';
import MyLinks from './components/ourServices/mylinks';
import RecentActivity from './components/ourServices/recentactivity';
import Tasks from './components/ourServices/shorttask/tasks';
import TaskCategory from './components/ourServices/shorttask/taskcategory';
import TaskSubCategory from './components/ourServices/shorttask/tasksubcategory';
import TaskDetail from './components/ourServices/shorttask/taskdetail';
import MyCampaign from './components/ourServices/shorttask/mycampaign';
import AcceptedTask from './components/ourServices/shorttask/acceptedtask';
import ReviewTasks from './components/ourServices/shorttask/reviewtask';
import CreateCampaign from './components/ourServices/shorttask/createcampaign';
import SubmitTaskProof from './components/ourServices/shorttask/submitproof';
import ShortTaskNotifications from './components/ourServices/shorttask/notification';
import ViewProfile from './components/ourServices/viewprofile';
import EditProfile from './components/ourServices/editprofile';
import Offers from './components/ourServices/offers';
import Surveys from './components/ourServices/survey';
import MyOffers from './components/ourServices/myOffers';
import MySurveys from './components/ourServices/mySurveys';
import Inbox from './components/ourServices/inbox';
import EditCampaign from './components/ourServices/shorttask/editcampaign';
import FavouriteTasks from './components/ourServices/shorttask/favouritetask';
import FavouriteEmployers from './components/ourServices/shorttask/favemployers';
import CampaignByUser from './components/ourServices/shorttask/campaignbyuser';
import HowGigsWorks from './components/ourServices/shorttask/howitworks';
import ScrollToTop from './components/common/scrollToTop';
import CareerNewsLanding from './components/ourServices/careernews/landing';
import NewsStreams from './components/ourServices/careernews/stream';
import NewsBranches from './components/ourServices/careernews/branch';
import NewsBranchDetail from './components/ourServices/careernews/branchdetail';
import NewsList from './components/ourServices/careernews/news';
import NewsDetail from './components/ourServices/careernews/detail';
import LatestGovNews from './components/ourServices/careernews/latestnews';
import LatestHindiNews from './components/ourServices/careernews/hindinews';
import LatestOtherNews from './components/ourServices/careernews/othernews';
import LastDateToApplyNews from './components/ourServices/careernews/lastdatetoapplynews';
import NewsPatternList from './components/ourServices/careernews/patternlist';
import NewsCityList from './components/ourServices/careernews/citylistadmissions';
import NewsCityJobsList from './components/ourServices/careernews/citylistjobs';
import NewsCityAdmissionsList from './components/ourServices/careernews/citylistadmissions';
import PatternNews from './components/ourServices/careernews/patternnews';
import QuotesLanding from './components/ourServices/quotes/landing';
import QuotesTopics from './components/ourServices/quotes/topics';
import QuotesList from './components/ourServices/quotes/quoteslist';
import QuotesAuthors from './components/ourServices/quotes/authors';
import LyricsAlbums from './components/ourServices/lyrics/albums';
import LyricsList from './components/ourServices/lyrics/lyricslistbyalbum';
import LyricsLyricists from './components/ourServices/lyrics/lyricists';
import LyricsArtist from './components/ourServices/lyrics/artitst';
import LyricsListByAlbum from './components/ourServices/lyrics/lyricslistbyalbum';
import LyricsListByArtist from './components/ourServices/lyrics/lyricslistbyartist';
import LyricsListByLyricist from './components/ourServices/lyrics/lyricslistbylyricist';
import LyricsDetail from './components/ourServices/lyrics/detail';
import QuotesDetail from './components/ourServices/quotes/detail';
import BlogCategory from './components/ourServices/blogs/category';
import BlogSubCategory from './components/ourServices/blogs/subcategory';
import BlogsList from './components/ourServices/blogs/list';
import BlogDetail from './components/ourServices/blogs/detail';
import TutorialCategory from './components/ourServices/tutorials/category';
import TutorialsList from './components/ourServices/tutorials/list';
import TutorialDetail from './components/ourServices/tutorials/detail';
import CorporateIndustries from './components/ourServices/corporate/industries';
import CorporateCompaniesList from './components/ourServices/corporate/company';
import CorporateDetail from './components/ourServices/corporate/detail';
import EducatorCategory from './components/ourServices/educator/category';
import EducatorsList from './components/ourServices/educator/educatorlist';
import EducatorDetail from './components/ourServices/educator/detail';
import CourseDetail from './components/ourServices/educator/coursedetail';
import ForumSubject from './components/ourServices/forum/subject';
import ForumTopic from './components/ourServices/forum/topic';
import ForumList from './components/ourServices/forum/forumlist';
import ForumDetail from './components/ourServices/forum/detail';
import MyForum from './components/ourServices/forum/myforum';
import PostAQuestion from './components/ourServices/forum/post';
import EditQuestion from './components/ourServices/forum/edit';
import MyBlogsList from './components/ourServices/blogs/myblogs';
import PostABlog from './components/ourServices/blogs/post';
import EditBlog from './components/ourServices/blogs/edit';
import MyTutorialsList from './components/ourServices/tutorials/mytutorials';
import PostATutorial from './components/ourServices/tutorials/post';
import EditTutorial from './components/ourServices/tutorials/edit';
import AddChapter from './components/ourServices/tutorials/addchapter';
import EditChapter from './components/ourServices/tutorials/editchapter';
// import BlogSubCategory from './components/ourServices/blogs/subcategory';
// import BlogsList from './components/ourServices/blogs/list';



function App() {
  const { user } = useAuth();
  const queryString = window.location.search;

  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <VerifyUserStatusProvider>
          <FeedProvider>
            <SearchProvider>
              <ToastContainer />
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Feeds />} exact />
                  <Route path="/login" element={<Navigate replace to={`/${queryString}`} />} exact />

                  <Route path="/feeds" element={<Feeds />} exact />
                  <Route
                    path="my-profile/change-password"
                    element={<ChangePassword />}
                    exact />
                  <Route
                    path="purchase-credits"
                    element={<Plans />}
                    exact />
                  <Route
                    path="payment-details"
                    element={<PaymentDetails />}
                    exact />

                  <Route
                    path="user/:username"
                    element={<ViewProfile />}
                    exact />
                  <Route
                    path="my-profile"
                    element={<ViewProfile />}
                    exact />
                  <Route
                    path="my-profile/edit"
                    element={<EditProfile />}
                    exact />
                  <Route
                    path="leaderboard"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="offers"
                    element={<Offers />}
                    exact />
                  <Route
                    path="offers/my-offers"
                    element={<MyOffers />}
                    exact />
                  <Route
                    path="inbox"
                    element={<Inbox />}
                    exact />
                  <Route
                    path="surveys"
                    element={<Surveys />}
                    exact />
                  <Route
                    path="surveys/my-surveys"
                    element={<MySurveys />}
                    exact />
                  <Route
                    path="finance"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="withdrawal"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="credit-purchase-history"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="credit-history"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="transaction-history"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="claim-credits"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="finance"
                    element={<CreditEarned />}
                    exact />
                  <Route
                    path="my-profile/my-links"
                    element={<MyLinks />}
                    exact />
                  <Route
                    path="my-profile/recent-activity"
                    element={<RecentActivity />}
                    exact />
                  <Route
                    path="profile-verification"
                    element={<ProfileVerification />}
                    exact />
                  <Route
                    path="raise-ticket"
                    element={<RaiseTicket />}
                    exact />
                  <Route
                    path="help-and-support"
                    element={<HelpAndSupport />}
                    exact />
                  <Route
                    path="ask-me-anything"
                    element={<AskMeAnything />}
                    exact />
                  <Route path=":name" element={<QuestionBank />} exact />
                  <Route path="feeds/:id/:slug" element={<FeedDetail />} exact />
                  <Route
                    path=":moduleName/favourite-questions"
                    element={<FavouriteQuestions />}
                    exact />
                  <Route
                    path=":name/:catName"
                    element={<QuestionBankCategories />}
                    exact />
                  <Route
                    path=":name/:catName/:subCatName"
                    element={<QuestionsSubCategories />}
                    exact />
                  <Route
                    path=":name/:catName/:subCatName/:qName"
                    element={<QuestionFeed />}
                    exact />
                  <Route
                    path=":name/favourite-questions/:qName"
                    element={<QuestionFeed />}
                    exact />
                  <Route
                    path="gigs/tasks"
                    element={<Tasks />}
                    exact />

                  <Route
                    path="gigs"
                    element={<Tasks />}
                    exact />
                  <Route
                    path="gigs/how-it-works"
                    element={<HowGigsWorks />}
                    exact />
                  <Route
                    path="gigs/favourite-employers"
                    element={<FavouriteEmployers />}
                    exact />
                  <Route
                    path="gigs/my-campaign"
                    element={<MyCampaign />}
                    exact />
                  <Route
                    path="gigs/my-campaign/:mCSlug"
                    element={<TaskDetail />}
                    exact />
                  <Route
                    path="gigs/tasks/:tLSlug"
                    element={<TaskDetail />}
                    exact />
                  <Route
                    path="gigs/review-task/:rTSlug"
                    element={<TaskDetail />}
                    exact />
                  <Route
                    path="gigs/my-task/:mTSlug"
                    element={<TaskDetail />}
                    exact />
                  <Route
                    path="gigs/create-campaign"
                    element={<CreateCampaign />}
                    exact />
                  <Route
                    path="gigs/my-campaign/edit/:cId"
                    element={<EditCampaign />}
                    exact />
                  <Route
                    path="gigs/notifications"
                    element={<ShortTaskNotifications />}
                    exact />
                  <Route
                    path="gigs/my-task/submit-proof"
                    element={<SubmitTaskProof />}
                    exact />
                  <Route
                    path="gigs/my-task"
                    element={<AcceptedTask />}
                    exact />
                  <Route
                    path="gigs/review-task"
                    element={<ReviewTasks />}
                    exact />
                  <Route
                    path="gigs/favourite-campaigns"
                    element={<FavouriteTasks />}
                    exact />
                  <Route
                    path="gigs/favourite-employers/:favUserSlug/campaigns/:fUId"
                    element={<CampaignByUser />}
                    exact />
                  <Route
                    path="gigs/tasks/:userSlug/campaigns/:uId"
                    element={<FavouriteTasks />}
                    exact />
                  <Route
                    path="gigs/:taskCatName"
                    element={<TaskSubCategory />}
                    exact />
                  <Route
                    path="gigs/:taskCatName/:taskSubCatName/tasks"
                    element={<Tasks />}
                    exact />
                  <Route
                    path="gigs/:taskCatName/:taskSubCatName/tasks/:slug"
                    element={<TaskDetail />}
                    exact />

                  <Route
                    path="career-news"
                    element={<NewsStreams />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-careers-streams"
                    element={<NewsStreams />}
                    exact />
                  <Route
                    path="career-news/latest-exams-employment-news-today/:patternType"
                    element={<NewsPatternList />}
                    exact />
                  <Route
                    path="career-news/jobs/city-list/:stateId/:stateSlug"
                    element={<NewsCityJobsList />}
                    exact />
                  <Route
                    path="career-news/admissions/city-list/:stateId/:stateSlug"
                    element={<NewsCityAdmissionsList />}
                    exact />
                  <Route
                    path="career-news/:pattern/city-list/:stateId/:cityId/:stateSlug/:citySlug/news"
                    element={<PatternNews />}
                    exact />
                  <Route
                    path="career-news/:pattern/all-india-news"
                    element={<PatternNews />}
                    exact />

                  <Route
                    path="career-news/:pattern/:educationId/:educationSlug"
                    element={<PatternNews />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-branches-exams"
                    element={<NewsBranches />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-branches-exams/:streamId/:branchId/:branchSlug"
                    element={<NewsBranchDetail />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-careers-streams/:streamId/:streamSlug"
                    element={<NewsBranches />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-careers-streams/:streamId/:streamSlug/:branchId/:branchSlug"
                    element={<NewsBranchDetail />}
                    exact />
                  <Route
                    path="career-news/top-10-trending-branches-exams/:streamId/:branchId/:branchSlug/:newsPattern"
                    element={<NewsList />}
                    exact />
                  <Route
                    path="career-news/news/:newsId/:newsSlug"
                    element={<NewsDetail />}
                    exact />
                  <Route
                    path="career-news/:pattern/news/:newsId/:newsSlug"
                    element={<NewsDetail />}
                    exact />
                  <Route
                    path="career-news/hindi-news/:newsId/:newsSlug"
                    element={<NewsDetail />}
                    exact />
                  <Route
                    path="career-news/news/:newsId/:sCat/:nSlug/:patternSlug"
                    element={<NewsList />}
                    exact />
                  <Route
                    path="career-news/view-last-date-to-apply-on-jobs-and-admissions"
                    element={<LastDateToApplyNews />}
                    exact />
                  <Route
                    path="career-news/jobs-internship-details/:newsId/:newsSlug"
                    element={<NewsDetail />}
                    exact />
                  <Route
                    path="career-news/latest-hindi-news"
                    element={<LatestHindiNews />}
                    exact />
                  <Route
                    path="career-news/other-latest-10-careernews"
                    element={<LatestOtherNews />}
                    exact />

                  <Route
                    path="career-news/latest-exams-employment-news-today"
                    element={<LatestGovNews />}
                    exact />
                  <Route
                    path="quotes"
                    element={<QuotesTopics />}
                    exact />
                  <Route
                    path="quotes/topics"
                    element={<QuotesTopics />}
                    exact />
                  <Route
                    path="quotes/authors"
                    element={<QuotesAuthors />}
                    exact />
                  <Route
                    path="quotes/topics/:qCatId/:topicSlug"
                    element={<QuotesList />}
                    exact />
                  <Route
                    path="quotes/authors/:qAthrId/:athrSlug"
                    element={<QuotesList />}
                    exact />
                  <Route
                    path="quotes/detail/:quotesId/:quotesSlug"
                    element={<QuotesDetail />}
                    exact />
                  <Route
                    path="lyrics"
                    element={<LyricsAlbums />}
                    exact />
                  <Route
                    path="lyrics/lyricists"
                    element={<LyricsLyricists />}
                    exact />
                  <Route
                    path="lyrics/artists"
                    element={<LyricsArtist />}
                    exact />
                  <Route
                    path="lyrics/album/:albumId/:albumSlug"
                    element={<LyricsListByAlbum />}
                    exact />

                  <Route
                    path="lyrics/artist/:artistId/:artistSlug"
                    element={<LyricsListByArtist />}
                    exact />

                  <Route
                    path="lyrics/lyricist/:lyricistId/:lyricistSlug"
                    element={<LyricsListByLyricist />}
                    exact />

                  <Route
                    path="lyrics/detail/:lyricsId/:lyricsSlug"
                    element={<LyricsDetail />}
                    exact />
                  <Route
                    path="blogs"
                    element={<BlogCategory />}
                  />
                 <Route
                    path="blogs/:blogCatSlug"
                    element={<BlogSubCategory />}
                  />
                  <Route
                    path="blogs/topic"
                    element={<BlogSubCategory />}
                  />
                  <Route
                    path="blogs/topic/:blogSubCatSlug"
                    element={<BlogsList />}
                  />
                  <Route
                    path="blogs/detail/:blogSlug"
                    element={<BlogDetail />}
                  />
                  <Route
                    path="blogs/my-blogs"
                    element={<MyBlogsList />}
                  />
                  <Route
                    path="blogs/post-a-blog"
                    element={<PostABlog />}
                  />
                  <Route
                    path="blogs/my-blogs/edit-blog"
                    element={<EditBlog />}
                  />
                  <Route
                    path="tutorials"
                    element={<TutorialCategory />}
                  />  
                  <Route
                    path="tutorials/:tutorialTagSlug"
                    element={<TutorialsList />}
                  /> 
                   <Route
                    path="tutorials/detail/:tutorialSlug"
                    element={<TutorialDetail />}
                  /> 
                  <Route
                    path="tutorials/detail/:tutorialSlug/:chapterSlug"
                    element={<TutorialDetail />}
                  /> 
                  <Route
                    path="tutorials/my-tutorials"
                    element={<MyTutorialsList />}
                  />
                  <Route
                    path="tutorials/post-a-tutorial"
                    element={<PostATutorial />}
                  />
                  <Route
                    path="tutorials/:tutorialSlug/add-chapter"
                    element={<AddChapter />}
                  />
                  <Route
                    path="tutorials/:tutorialSlug/edit-chapter"
                    element={<EditChapter />}
                  />
                  <Route
                    path="tutorials/my-tutorials/edit-tutorial"
                    element={<EditTutorial />}
                  />
                  <Route
                    path="corporates"
                    element={<CorporateIndustries />}
                  /> 
                  <Route
                    path="corporates/:filterType/:typeWiseSlug"
                    element={<CorporateCompaniesList />}
                  /> 
                  <Route
                    path="corporates/company/:companySlug"
                    element={<CorporateDetail />}
                  /> 
                  <Route
                    path="educators"
                    element={<EducatorCategory />}
                  /> 
                  <Route
                    path="educators/:filterType/:typeWiseSlug"
                    element={<EducatorsList />}
                  />
                  <Route
                    path="educators/university/:educatorSlug"
                    element={<EducatorDetail />}
                  /> 
                  <Route
                    path="educators/course/:educatorSlug"
                    element={<CourseDetail />}
                  /> 
                  <Route
                    path="forum"
                    element={<ForumSubject />}
                  /> 
                  <Route
                    path="forum/:forumCatSlug"
                    element={<ForumTopic />}
                  /> 
                  <Route
                    path="forum/:forumCatSlug/:forumSubCatSlug"
                    element={<ForumList />}
                  /> 
                  <Route
                    path="forum/:forumCatSlug/:forumSubCatSlug/:forumSlug"
                    element={<ForumDetail />}
                  /> 
                  <Route
                    path="forum/my-forum"
                    element={<MyForum />}
                  /> 
                  <Route
                    path="forum/post-a-question"
                    element={<PostAQuestion />}
                  /> 
                  <Route
                    path="forum/my-forum/edit-question"
                    element={<EditQuestion />}
                  /> 
                </Route>
                <Route path="/" element={user ? <LandingScreen /> : <Navigate to="/login" />} exact />
                <Route path="/login" element={user ? <Navigate replace to="/" /> : <Login />} exact />
                <Route path="/redirect/:thid" element={<Redirection />} exact />
                <Route path="/register" element={user ? <Navigate replace to="/" /> : <Register />} exact />
                <Route path="/forgot-password" element={user ? <Navigate replace to="/" /> : <ForgotPassword />} exact />
                <Route path="/reset-password" element={user ? <Navigate replace to="/" /> : <ResetPassword />} exact />
                <Route path="/*" element={user ? <Navigate replace to="/" /> : <Login />} />
              </Routes>
            </SearchProvider>
          </FeedProvider>
        </VerifyUserStatusProvider>
      </AuthProvider>


    </BrowserRouter>
  );
}

export default App;
