import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../../Context/AuthContext';
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL
} from '../../../../utils/utils';
import {
    tutorialCommentAndReply,
    likeUnlikeDislike,
    getTutorialChaptersList,
    getTutorialDetailByChapter
} from '../../../../api/tutorial';
import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import { getTutorialDetails } from '../../../../api/tutorial';
import TutorialsComments from '../../../common/comments/tutorialsComments';
import PopularCareerCategories from '../../careernews/popularcategories';
import TutorialChapters from '../chapters';

function TutorialDetail() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [tutorialDetailData, setTutorialDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [tutorialId, setTutorialId] = useState();
    const [tutorialSlug, setTutorialSlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [tutorialChapters, setTutorialChapters] = useState();
    const [introLoad, setIntroLoad] = useState(false);
    const [dynamicDesc, setDynamicDesc] = useState();
    const [dynamicTitle, setDynamicTitle] = useState();

    // Fetches lyrics details when component mounts or params change
    useEffect(() => {
        if (params?.tutorialSlug) {
            const tId = (params?.tutorialSlug).split('-').pop();
            setTutorialSlug(params?.tutorialSlug);
            setTutorialId(tId)
            // if(!params?.chapterSlug){
            handleFetchTutorialDetail(tId);
            // }
            
            handleChaptersList(tId);
            if(params?.chapterSlug){
                const cId = (params?.chapterSlug).split('-').pop();
                handleTutorialDetailByChapter(tId, cId);
            }
        }
    }, []);

    // Handles fetching lyrics details by lyrics ID
    const handleFetchTutorialDetail = async (tId) => {
        setIntroLoad(true);
        const resTutorialsDetail = await getTutorialDetails({ token: globalToken, tutorialId:tId });
        // console.log('tutorials detail', resTutorialsDetail);
       
        setIsLiked(resTutorialsDetail?.data?.is_like);
        setIsDisliked(resTutorialsDetail?.data?.is_dislike);
        setLoading(false);
        setLoadingLoadMore(false);
        if (resTutorialsDetail?.success) {
            // setDynamicDesc(resTutorialsDetail?.data?.tutorial?.description);
            setIsNotFound(false);
            if (resTutorialsDetail?.data?.tutorial) {
                setTutorialDetailData(resTutorialsDetail?.data?.tutorial);
            } else {
                setTutorialDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false);
    }

    // Handles adding/removing lyrics from favorites
    // const FavouriteButton = ({ is_fav_camp }) => {
    //     const [favBtnLoading, setFavBtnLoading] = useState(false);

    //     const handleStartTask = async () => {
    //         setFavBtnLoading(true);
    //         const token = globalToken;
    //         const res = await bookMarkLyrics({ token: token, lyricId: tutorialDetailData.id, favourite: is_fav_camp ? 0 : 1 });
    //         if (res?.success) {
    //             handleFetchTutorialDetail(tutorialDetailData.id);
    //         }
    //         setFavBtnLoading(false);
    //     }

    //     return (
    //         <button
    //             type="button"
    //             disabled={favBtnLoading}
    //             onClick={handleStartTask}
    //             className="btn bg-white p-0 d-flex btn-like-circular"
    //             style={{ border: 'none', boxShadow: 'none' }}
    //         >
    //             {favBtnLoading ? (
    //                 <div className="h-5 w-5">
    //                     <Loadingicon />
    //                 </div>
    //             ) : (
    //                 <>
    //                     {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
    //                 </>
    //             )}
    //         </button>
    //     );
    // }

    // Handles sharing the tutorial
    const InlineShare = ({ tutorialsData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubTutorial}/detail/${tutorialsData?.slug}`,
                            quote: `${tutorialsData?.description}`,
                            hashing: `#tuteehub`,
                            title: `${tutorialsData?.title}`,
                        }}
                        isCustomPos={true}
                    />
                ) : null}
                <span
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, tutorialId: tutorialDetailData.id, voteType });
        if (resLikeUnlike?.success) {
            handleFetchTutorialDetail(tutorialDetailData.id);
            toast.success(resLikeUnlike?.data?.data?.message);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {
        commentLoader(true);
        try {
            const resCommentCommit = await tutorialCommentAndReply({
                token: globalToken,
                tutorialId: tutorialDetailData?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message);
                handleFetchTutorialDetail(tutorialDetailData?.id);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };

   

    // get all chapters list
    const handleChaptersList = async (tId) =>{
        const resChaptersList = await getTutorialChaptersList({token:globalToken, tutorialId:tId})
        if(resChaptersList?.success){
            setTutorialChapters(resChaptersList?.data.reverse())
        }
    }

    // get detail by chapter
    const handleTutorialDetailByChapter = async (tId, cId) =>{
        setIntroLoad(false);
        const resDetailByChapter = await getTutorialDetailByChapter({token:globalToken, tutorialId:tId, chapterId:cId})
        if(resDetailByChapter?.success){           
            // console.log(resDetailByChapter)
            setDynamicDesc(resDetailByChapter?.data?.description);
            setDynamicTitle(resDetailByChapter?.data?.title);
            // setTutorialChapters(resChaptersList?.data)
        }
        setLoading(false);
    }

    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : (
                <>
                    <div className='d-flex justify-content-between mb-4'>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                            </li>
                            {
                                tutorialDetailData?.tag_list? <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/tutorials/${tutorialDetailData?.tag_list[0]?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{tutorialDetailData?.tag_list[0]?.name}</NavLink>
                            </li>:null
                            }
                           
                         <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.tutorialSlug)}
                            </li>
                        </ol>
                    </nav>
                    <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/my-tutorials')}
                            >
                                My Tutorials
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/post-a-tutorial')}
                            >
                            Post A Tutorial
                            </button>                    
                        </div>
                    </div>
                    <div className="row">
                    <div className={`${tutorialChapters?.length?"col-md-9":"col-md-12"}`}>
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                            <div className="px-0">
                                <div className="flex-column d-flex flex-md-row justify-content-between">

                                    <div className="mr-md-5 pr-md-5 flex-1">
                                        <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                            <strong>{tutorialDetailData?.title}</strong>
                                            <span className="d-flex gap-3 align-items-center">
                                                {/* <FavouriteButton  is_fav_camp={tutorialDetailData?.isuserfavourite==0?false:true}/> */}
                                                <InlineShare tutorialsData={tutorialDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive}/> 
                        </span>
                                        </h1>
                                        <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                                                
                                            <span className="text-black-50 gig-table items-center d-flex">
                                           
                                             <span>{nFormatter(tutorialDetailData?.views || 0)} views</span>
                                             <span className="big-dots"></span>
                                             <span>{tutorialDetailData?.comments_count || 0} comments</span>
                                             <span className="big-dots"></span>
                                             <span>{tutorialDetailData?.likes_count || 0} up votes</span>
                                             {
                                                    upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-up cursor-pointer ms-2 ${isLiked && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(isLiked ? "unlike" : "like",'lke')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{tutorialDetailData?.dislikes_count || 0} down votes</span>
                                             {
                                                    downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-down cursor-pointer ms-2 ${isDisliked && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{nFormatter(tutorialDetailData?.share_count)} shares</span>
                                             <span className='big-dots'></span>
                                             <span>{convertDateFormat(tutorialDetailData?.posting_date || tutorialDetailData?.created_at)}</span>
                                             </span>
                                            {/* <span className="text-black-50">Published on {convertDateFormat(tutorialDetailData?.posting_date)}</span> */}

                                        </div>
                                        <div className="thumbnail-containers rounded w-100">                                            {
                                                tutorialDetailData?.image?<img src={CDN_BASE_URL + tutorialDetailData?.image} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />:null
                                            }
                                            
                                        </div>
                                        {
                                            introLoad?<>
                                            <div className='chapter-head-container mt-4'>
                                            <h5 className='m-0'><strong><i className='fa fa-user me-2'></i> About the Author</strong></h5>
                                            <div className="bottom-line-container position-relative">
                                                <hr className='bottom-line-gray mt-2 mb-2'/>
                                                <span className='bottom-line-orange'></span>
                                            </div>
                                       </div>
                                       <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: modifyAnchorTags(`${tutorialDetailData?.about?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                        }}></div>
                                       <div className='chapter-head-container mt-4'>
                                            <h5 className='m-0'><strong><i className='fa fa-book me-2'></i> About the Tutorial</strong></h5>
                                            <div className="bottom-line-container position-relative">
                                                <hr className='bottom-line-gray mt-2 mb-2'/>
                                                <span className='bottom-line-orange'></span>
                                            </div>
                                       </div>
                                        <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: modifyAnchorTags(`${tutorialDetailData?.description?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                        }}></div></>:<>
                                        <div className='chapter-head-container mt-4'>
                                            <h5 className='m-0'><strong>{dynamicTitle}</strong></h5>
                                            <div className="bottom-line-container position-relative">
                                                <hr className='bottom-line-gray mt-2 mb-2'/>
                                                <span className='bottom-line-orange'></span>
                                            </div>
                                       </div>
                                        <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: `${dynamicDesc}`
                                        }}></div>
                                        </>
                                        }
                                        

                                    </div>

                                </div>


                            </div>
                        </div>
                       
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({tutorialDetailData?.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <TutorialsComments cData={tutorialDetailData} handleSubmitComment={handleSubmitComment}/>
                        </div>
                       
                    </div>
                    {
                        tutorialChapters?.length ?<div className="col">
                        <div className="position-sticky" style={{ top: 80 }}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-1">
                                
                                <TutorialChapters chaptersList={tutorialChapters} tutorialSlug={params?.tutorialSlug} tutorialId={tutorialId} handleFetchTutorialDetail={handleFetchTutorialDetail} handleTutorialDetailByChapter={handleTutorialDetailByChapter} showArrow={true} title={'Chapters'}/>
                            </div>
                            {/* <div className="py-4 mt-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="d-flex align-items-center w-100 py-md-2 py-2 px-0 w-100 w-md-auto max-left-contents justify-content-between">
                                    <div className="thumbnail-containers mr-3">

                                        <img src={require(`../../../../assets/images/logo_other_platform.webp`)} width={50} height={50} className="bg-gray border" style={{ borderRadius: 100 }} />
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                                            <strong>{'TuteeHUB'}</strong> </h5>
                                        <p className="text-black-50 text-capitalize mb-0 d-flex align-items-center gap-2">
                                            <strong className="text-blacks d-flex align-items-center gap-1">1L+ Subscribers
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                            </div> */}

                        </div>
                    </div>:null
                    }
                   
            {/* <div className='col-md-12'>
            <div className="col-md-12 my-4 w-full  border-none child-menu   card-body">
            <SimilarNews newsSlug={tutorialDetailData?.slug} patternLinks={patternLinks} redirectionFn={()=>alert(1)} stripBottom={false}/>
          </div>
          </div> */}
                </div>
                </>
            )}
        </>
    );
}

export default TutorialDetail;
