import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate } from 'react-router-dom';
import TutorialCategoryList from '../common/catsubcat';
import { getCorporateIndustries, getCorporateWiseFilter } from '../../../../api/corporate';
import CorporateIndustryList from '../common/catsubcat';
function CorporateIndustries() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const navigation = useNavigate();
    const [filterType, setFilterType] = useState('industry');



    useEffect(() => {
        handleFetchAllCorporateIndustries(1, filterType);
    }, [])

    // fetch all industries type
    const handleFetchAllCorporateIndustries = async (pageNo, fType) => {
        const resAllCorporateWiseFilter = await getCorporateWiseFilter({ pageNo: pageNo, fType });
        if (resAllCorporateWiseFilter?.success) {
            setIsNotFound(false);
            
            const hasNextPage = resAllCorporateWiseFilter?.industries?.next_page_url || 
                                resAllCorporateWiseFilter?.states?.next_page_url || 
                                resAllCorporateWiseFilter?.cities?.next_page_url ||
                                resAllCorporateWiseFilter?.cTypes?.next_page_url ||
                                resAllCorporateWiseFilter?.localities?.next_page_url;
                                
            hasNextPage ? setShowLoadMore(true) : setShowLoadMore(false);
            
            const hasData = (resAllCorporateWiseFilter?.industries?.data?.length || 
                             resAllCorporateWiseFilter?.states?.data?.length ||
                             resAllCorporateWiseFilter?.cities?.data?.length ||
                             resAllCorporateWiseFilter?.cTypes?.data?.length ||
                             resAllCorporateWiseFilter?.localities?.data?.length);
            
            if (hasData && pageNo === 1) {
                setAllContent(resAllCorporateWiseFilter?.industries?.data || 
                              resAllCorporateWiseFilter?.states?.data || 
                              resAllCorporateWiseFilter?.cities?.data || 
                              resAllCorporateWiseFilter?.cTypes?.data || 
                              resAllCorporateWiseFilter?.localities?.data);
            } else if (hasData && pageNo !== 1) {
                setAllContent([...allContent, ...(resAllCorporateWiseFilter?.industries?.data || 
                                                resAllCorporateWiseFilter?.states?.data || 
                                                resAllCorporateWiseFilter?.cities?.data || 
                                                resAllCorporateWiseFilter?.cTypes?.data || 
                                                resAllCorporateWiseFilter?.localities?.data)]);
            } else {
                if (pageNo === 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
   

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more category data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllCorporateIndustries(nxtPage, filterType);
        setPageNo(nxtPage);
    }


    // filter type data
    const filterTypeData = [
        {
            name:'Industry',
            value:'industry',
        },{
            name:'State',
            value:'state'
        },{
            name:'City',
            value:'city'
        },{
            name:'Locality',
            value:'locality'
        },{
            name:'Company Type',
            value:'type'
        }
    ]


    // handle filter change 
    const handleFilterChange = (e) =>{
        setPageNo(1);
        const fValue = e.target.value;
        setFilterType(fValue);
        handleFetchAllCorporateIndustries(1, fValue);
    }

    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={filterType=="type"?`<strong>Top 10 Different Companies Types Added In ${yearSatra()}</strong>`:`<strong>Top 10 Companies ${filterType} Wise Added In ${yearSatra()}</strong>`} subTitle={`Explore TuteeHUB Corporate Directory as a rich source of business information across every location in the country. In the following listing you will find company profiles, commercial registration information and more on millions of Indian companies, all categorized by Industry.`} />
                        <div className='filter-container ms-4' style={{minWidth:200}}>
                                <select className='form-control mw-100'  onChange={handleFilterChange}>
                                    {
                                        filterTypeData?.map((fData, i)=><option key={i+'ftype'} value={fData?.value}>{fData?.name}</option>)
                                    }
                                    
                                </select>
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <CorporateIndustryList content={content} boxSize='col-md-3 mb-3' key={sIndex} type="content" redirectionFn={() => navigation(`/corporates/${filterType}/${content?.slug}`)} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default CorporateIndustries;